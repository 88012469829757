<template>
  <div class="hello">
    <div class="modal">
      <div @click="anotherTime" class="close"></div>
      <div v-if="!$route.name.includes('profile-activity')" class="h1">
        {{ $t('hello_h1') }}
      </div>
      <strong>
        {{ $t('hello_firststep') }}
      </strong>
      {{ $t('hello_learn') }}
      <ul>
        <li>
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">1)</b> {{ $t('hello_follow_vk') }}
          </strong>
          <ul>
            <li>
              1) {{ $t('hello_follow_group') }} <a href="https://vk.com/unotaloneru" target="_blank">ВКонтакте</a>
            </li>
            <li>
              2) Найдите ID своей страницы и введите его в поле ниже для проверки

              <figure style="margin:10px 0px;">
                <a href="https://unotalone.su/static_files/get_vkid.png" target="_blank">
                  <img src="/static_files/get_vkid.png" alt="" style="width:200px;opacity:0.9;">
                </a>
                <figcaption style="font-size:1.4rem;color:rgba(255,255,255,0.65);">
                  Профиль - Настройки - Общее - Адрес страницы - Изменить
                </figcaption>
              </figure>
            </li>
            <li>
              3) {{ $t('hello_check_follow') }}
              <div style="margin-top:10px;">
                <input type="text" v-model="socId" placeholder="Номер страницы (ID)">
                <div style="display:inline-block;vertical-align:middle;">
                  <button @click="getReward('vk_group_join')" class="mini" :class="{ wi: loading }" :disabled="loading">
                    <img v-if="loading" src="/static_files/icons/loader.gif" alt="">
                    {{ $t('check') }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <input
            :data-tgbot-myid="user.id"
            :value="`TG ID`"
            type="text"
            readonly
            style="position:absolute;top:-9999px;opacity:0;"
          />
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">2)</b> {{ $t('hello_follow_tg') }}
          </strong>
          <ul>
            <li>
              1) {{ $t('hello_follow_group') }} <a href="https://t.me/unotaloneru" target="_blank">{{ $t('tg') }}</a>  
            </li>
            <li>
              2) {{ $t('hello_follow_tg_p1') }} <a href="https://t.me/unotalone_bot" target="_blank">{{ $t('hello_follow_tg_bot') }}</a>,
              {{ $t('hello_follow_tg_p2') }} <span @click="shareID('tgbot-myid')" class="share-ref">TG ID</span> 
            </li>
            <li>
              3) {{ $t('hello_check_follow') }}

              <div style="margin:5px 0px;">
                <input type="text" v-model="socId" placeholder="ID вашего профиля">
                <div style="display:inline-block;vertical-align:middle;">
                  <button @click="getReward('tg_group_join')" class="mini" :class="{ wi: loading }" :disabled="loading">
                    <img v-if="loading" src="/static_files/icons/loader.gif" alt="">
                    {{ $t('check') }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <input
            :data-user-ref="user.id"
            :value="`https://unotalone.su?ref=${user.id}`"
            type="text"
            readonly
            style="position:absolute;top:-9999px;opacity:0;"
          />
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">3)</b> {{ $t('hello_invite_ref') }}
          </strong>
          {{ $t('hello_invite_ref_p')  }} <nuxt-link to="/profile/ref">{{ $t('balance') }}</nuxt-link>
          <div @click="shareID('user-ref')" class="share-ref">https://unotalone.su?ref={{ user.id }}</div>
        </li>
        <li>
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">4)</b> {{ $t('hello_use') }}
          </strong>
          {{ $t('hello_use_p') }}
          <nuxt-link :to="localePath(`/donate`)">Premium</nuxt-link> {{ $t('or') }} <nuxt-link :to="localePath(`/donate`)">{{ $t('hello_viewspacks') }}</nuxt-link>.
        </li>
        <li>
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">5)</b> {{ $t('hello_21h') }}
          </strong>
          {{ $t('hello_21h_p') }}
          <nuxt-link :to="localePath(`/vmeste?common`)">{{ $t('go_to_room') }}</nuxt-link>.
        </li>
        <li>
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">6)</b> {{ $t('hello_invite') }}
          </strong>
          <nuxt-link :to="localePath(`/chat`)">{{ $t('hello_invite_dating') }}</nuxt-link>. {{ $t('hello_invite_p') }}
        </li>
        <li>
          <strong>
            <b style="color:#10c44c;font-size:2rem;margin-right:1px;">7)</b> {{ $t('hello_service_like') }}
          </strong>
          {{ $t('hello_service_like_p') }} <nuxt-link :to="localePath(`/donate`)">{{ $t('donate') }}</nuxt-link>
        </li>
      </ul>
      <div v-if="!$route.name.includes('profile-activity')" class="actions">
        <div style="display:inline-block;">
          <button @click="anotherTime" class="wi">
            <img src="/static_files/icons/sad.png" alt="">
            {{ $t('hello_next_time') }}
          </button>
          <div style="font-size:1.4rem;color:rgba(255,255,255,0.5);text-align:center;cursor:pointer;">
            <span @click="hidden">{{ $t('hello_hide') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['user']),
  data() {
    return {
      loading: false,
      socId: '',
    }
  },
  methods: {
    hidden() {
      localStorage.setItem('hello', 'true')
      this.anotherTime()
    },
    async getReward(task) {
      if (!this.user) {
        this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (!this.socId) {
        this.$store.commit('setError', { status: 'info', msg: 'Укажите ID' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
        return
      }

      if (this.loading) return
      this.loading = true

      const { data } = await this.$axios.get(`/api/activity?task=${task}&id=${this.socId}`)
      this.loading = false

      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setError', { status: 'ok', msg: data.message })
        setTimeout(() => {
          this.$store.commit('clearError')

          // if (this.$route.name !== 'profile-activity') location.reload()
          location.reload()
        }, 2000)
      }
    },
    shareID(dataId) {
      const input = document.querySelector(`[data-${dataId}="${this.user.id}"]`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'Текст скопирован' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    anotherTime() {
      this.$emit('close')
    },
  }
} 
</script>

<style lang="stylus">
.hello
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  background-color: rgba(0, 0 0, 0.5)
  z-index: 10
  .modal
    position: fixed
    top: calc(50% - 300px)
    left: calc(50% - 300px)
    padding: 10px
    width: 600px
    height: 600px
    background-color: #1f1b2f
    border: 2px solid rgba(255, 255, 255, 0.15)
    border-radius: 4px
    overflow-x: hidden
    overflow-y: auto
    .close
      display: inline-block
      position: absolute
      top: 10px
      left: 10px
      width: 14px
      height: 14px
      background-image: url('/static_files/icons/cancel-dark.svg')
      background-size: 14px
      cursor: pointer
      opacity: 0.75
      transition: opacity 0.2s ease
      z-index: 2
      &:hover
        opacity: 1
    .h1
      margin-bottom: 30px
      font-size: 3rem
      text-align: center
    .actions
      position: relative
      padding: 5px 0px
      background-color: #1f1b2f
      text-align: center
    ul
      margin-top: 20px
      li
        position: relative
        margin: 30px 0px
        padding: 12px 10px
        border: 1px solid rgba(255, 255, 255, 0.35)
        &::before
          content: ''
          position: absolute
          top: 0px
          left: 0px
          width: 15px
          height: 15px
          border-top: 1px solid
          border-left: 1px solid
          border-color: #fff
        &::after
          content: ''
          position: absolute
          bottom: 0px
          right: 0px
          width: 15px
          height: 15px
          border-bottom: 1px solid
          border-right: 1px solid
          border-color: #fff
        strong
          display: block
          font-size: 1.6rem
          margin-bottom: 5px
        a
          border-bottom: 1px solid rgba(255, 0, 0, 0.75)
        li
          margin: 10px 0px
          border: 1px solid rgba(255, 255, 255, 0.15)
          &::before, &::after
            display: none
    .share-ref
      display: inline-block
      padding: 2px 10px
      font-weight: 400
      background-color: #3a3352
      border-radius: 4px
      cursor: pointer
</style>