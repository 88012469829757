import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a6512074 = () => interopDefault(import('../pages/3f4g5q6/index.vue' /* webpackChunkName: "pages/3f4g5q6/index" */))
const _3a2ebbdf = () => interopDefault(import('../pages/4wg8a/index.vue' /* webpackChunkName: "pages/4wg8a/index" */))
const _3c1f5839 = () => interopDefault(import('../pages/adblock.vue' /* webpackChunkName: "pages/adblock" */))
const _304c07e0 = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _f8e8e63e = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _0eb85081 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3f08e30b = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _26170cf9 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _35f8f868 = () => interopDefault(import('../pages/common.vue' /* webpackChunkName: "pages/common" */))
const _2ac73576 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _3d73bde1 = () => interopDefault(import('../pages/dobro1/index.vue' /* webpackChunkName: "pages/dobro1/index" */))
const _62124cd3 = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _c6328a28 = () => interopDefault(import('../pages/donate-paw/index.vue' /* webpackChunkName: "pages/donate-paw/index" */))
const _07b24e09 = () => interopDefault(import('../pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _db9a5f14 = () => interopDefault(import('../pages/films-4k.vue' /* webpackChunkName: "pages/films-4k" */))
const _09db4b06 = () => interopDefault(import('../pages/guess.vue' /* webpackChunkName: "pages/guess" */))
const _bd51a73c = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _1d39f82f = () => interopDefault(import('../pages/name/index.vue' /* webpackChunkName: "pages/name/index" */))
const _d4c6d570 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _eb3a5312 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _24054eca = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _6e8e3fd8 = () => interopDefault(import('../pages/promocodes.vue' /* webpackChunkName: "pages/promocodes" */))
const _6b621603 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _3cb33abc = () => interopDefault(import('../pages/sync.vue' /* webpackChunkName: "pages/sync" */))
const _2c388e68 = () => interopDefault(import('../pages/tag/index.vue' /* webpackChunkName: "pages/tag/index" */))
const _af6834ec = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _8391e480 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5f07707a = () => interopDefault(import('../pages/vmeste/index.vue' /* webpackChunkName: "pages/vmeste/index" */))
const _d503d644 = () => interopDefault(import('../pages/vpn1.vue' /* webpackChunkName: "pages/vpn1" */))
const _b00b2b6a = () => interopDefault(import('../pages/3f4g5q6/guess.vue' /* webpackChunkName: "pages/3f4g5q6/guess" */))
const _c589d680 = () => interopDefault(import('../pages/4wg8a/articles.vue' /* webpackChunkName: "pages/4wg8a/articles" */))
const _ead2844a = () => interopDefault(import('../pages/4wg8a/chat.vue' /* webpackChunkName: "pages/4wg8a/chat" */))
const _37b12612 = () => interopDefault(import('../pages/4wg8a/comments.vue' /* webpackChunkName: "pages/4wg8a/comments" */))
const _718ce494 = () => interopDefault(import('../pages/4wg8a/desc.vue' /* webpackChunkName: "pages/4wg8a/desc" */))
const _11c3c2b2 = () => interopDefault(import('../pages/4wg8a/donate.vue' /* webpackChunkName: "pages/4wg8a/donate" */))
const _4cd24a08 = () => interopDefault(import('../pages/4wg8a/feedback.vue' /* webpackChunkName: "pages/4wg8a/feedback" */))
const _146eeaa2 = () => interopDefault(import('../pages/4wg8a/kino.vue' /* webpackChunkName: "pages/4wg8a/kino" */))
const _25213073 = () => interopDefault(import('../pages/4wg8a/streamers.vue' /* webpackChunkName: "pages/4wg8a/streamers" */))
const _7c5f33e2 = () => interopDefault(import('../pages/4wg8a/unsubs.vue' /* webpackChunkName: "pages/4wg8a/unsubs" */))
const _27aa6f99 = () => interopDefault(import('../pages/4wg8a/vmeste.vue' /* webpackChunkName: "pages/4wg8a/vmeste" */))
const _4c6da492 = () => interopDefault(import('../pages/4wg8a/wf.vue' /* webpackChunkName: "pages/4wg8a/wf" */))
const _7f82f942 = () => interopDefault(import('../pages/auth/error.vue' /* webpackChunkName: "pages/auth/error" */))
const _398b5d86 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _52c3d2d2 = () => interopDefault(import('../pages/dobro1/success.vue' /* webpackChunkName: "pages/dobro1/success" */))
const _8ebf4d12 = () => interopDefault(import('../pages/donate-paw/fail/index.vue' /* webpackChunkName: "pages/donate-paw/fail/index" */))
const _17de35e0 = () => interopDefault(import('../pages/donate-paw/success/index.vue' /* webpackChunkName: "pages/donate-paw/success/index" */))
const _6d76d656 = () => interopDefault(import('../pages/profile/activity.vue' /* webpackChunkName: "pages/profile/activity" */))
const _142722f8 = () => interopDefault(import('../pages/profile/articles.vue' /* webpackChunkName: "pages/profile/articles" */))
const _4e8acf20 = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _27ec9df2 = () => interopDefault(import('../pages/profile/friendly.vue' /* webpackChunkName: "pages/profile/friendly" */))
const _67e59b1d = () => interopDefault(import('../pages/profile/history.vue' /* webpackChunkName: "pages/profile/history" */))
const _61749540 = () => interopDefault(import('../pages/profile/notifi.vue' /* webpackChunkName: "pages/profile/notifi" */))
const _d5e40fc8 = () => interopDefault(import('../pages/profile/ref.vue' /* webpackChunkName: "pages/profile/ref" */))
const _03bd6168 = () => interopDefault(import('../pages/articles/_id/index.vue' /* webpackChunkName: "pages/articles/_id/index" */))
const _71538076 = () => interopDefault(import('../pages/catalog/_id/index.vue' /* webpackChunkName: "pages/catalog/_id/index" */))
const _1343829e = () => interopDefault(import('../pages/favorites/_id.vue' /* webpackChunkName: "pages/favorites/_id" */))
const _0e2538cc = () => interopDefault(import('../pages/name/_id/index.vue' /* webpackChunkName: "pages/name/_id/index" */))
const _4c617e34 = () => interopDefault(import('../pages/tag/_id.vue' /* webpackChunkName: "pages/tag/_id" */))
const _2b5fbe65 = () => interopDefault(import('../pages/vmeste/_id/index.vue' /* webpackChunkName: "pages/vmeste/_id/index" */))
const _32de64b5 = () => interopDefault(import('../pages/catalog/_id/_section/index.vue' /* webpackChunkName: "pages/catalog/_id/_section/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/3f4g5q6",
    component: _a6512074,
    name: "3f4g5q6___ru"
  }, {
    path: "/4wg8a",
    component: _3a2ebbdf,
    name: "4wg8a___ru"
  }, {
    path: "/adblock",
    component: _3c1f5839,
    name: "adblock___ru"
  }, {
    path: "/app",
    component: _304c07e0,
    name: "app___ru"
  }, {
    path: "/articles",
    component: _f8e8e63e,
    name: "articles___ru"
  }, {
    path: "/be",
    component: _0eb85081,
    name: "index___be"
  }, {
    path: "/catalog",
    component: _3f08e30b,
    name: "catalog___ru"
  }, {
    path: "/chat",
    component: _26170cf9,
    name: "chat___ru"
  }, {
    path: "/common",
    component: _35f8f868,
    name: "common___ru"
  }, {
    path: "/copyright",
    component: _2ac73576,
    name: "copyright___ru"
  }, {
    path: "/dobro1",
    component: _3d73bde1,
    name: "dobro1___ru"
  }, {
    path: "/donate",
    component: _62124cd3,
    name: "donate___ru"
  }, {
    path: "/donate-paw",
    component: _c6328a28,
    name: "donate-paw___ru"
  }, {
    path: "/en",
    component: _0eb85081,
    name: "index___en"
  }, {
    path: "/favorites",
    component: _07b24e09,
    name: "favorites___ru"
  }, {
    path: "/films-4k",
    component: _db9a5f14,
    name: "films-4k___ru"
  }, {
    path: "/guess",
    component: _09db4b06,
    name: "guess___ru"
  }, {
    path: "/help",
    component: _bd51a73c,
    name: "help___ru"
  }, {
    path: "/name",
    component: _1d39f82f,
    name: "name___ru"
  }, {
    path: "/popular",
    component: _d4c6d570,
    name: "popular___ru"
  }, {
    path: "/privacy",
    component: _eb3a5312,
    name: "privacy___ru"
  }, {
    path: "/profile",
    component: _24054eca,
    name: "profile___ru"
  }, {
    path: "/promocodes",
    component: _6e8e3fd8,
    name: "promocodes___ru"
  }, {
    path: "/signin",
    component: _6b621603,
    name: "signin___ru"
  }, {
    path: "/sync",
    component: _3cb33abc,
    name: "sync___ru"
  }, {
    path: "/tag",
    component: _2c388e68,
    name: "tag___ru"
  }, {
    path: "/termsofuse",
    component: _af6834ec,
    name: "termsofuse___ru"
  }, {
    path: "/uk",
    component: _0eb85081,
    name: "index___uk"
  }, {
    path: "/unsubscribe",
    component: _8391e480,
    name: "unsubscribe___ru"
  }, {
    path: "/vmeste",
    component: _5f07707a,
    name: "vmeste___ru"
  }, {
    path: "/vpn1",
    component: _d503d644,
    name: "vpn1___ru"
  }, {
    path: "/3f4g5q6/guess",
    component: _b00b2b6a,
    name: "3f4g5q6-guess___ru"
  }, {
    path: "/4wg8a/articles",
    component: _c589d680,
    name: "4wg8a-articles___ru"
  }, {
    path: "/4wg8a/chat",
    component: _ead2844a,
    name: "4wg8a-chat___ru"
  }, {
    path: "/4wg8a/comments",
    component: _37b12612,
    name: "4wg8a-comments___ru"
  }, {
    path: "/4wg8a/desc",
    component: _718ce494,
    name: "4wg8a-desc___ru"
  }, {
    path: "/4wg8a/donate",
    component: _11c3c2b2,
    name: "4wg8a-donate___ru"
  }, {
    path: "/4wg8a/feedback",
    component: _4cd24a08,
    name: "4wg8a-feedback___ru"
  }, {
    path: "/4wg8a/kino",
    component: _146eeaa2,
    name: "4wg8a-kino___ru"
  }, {
    path: "/4wg8a/streamers",
    component: _25213073,
    name: "4wg8a-streamers___ru"
  }, {
    path: "/4wg8a/unsubs",
    component: _7c5f33e2,
    name: "4wg8a-unsubs___ru"
  }, {
    path: "/4wg8a/vmeste",
    component: _27aa6f99,
    name: "4wg8a-vmeste___ru"
  }, {
    path: "/4wg8a/wf",
    component: _4c6da492,
    name: "4wg8a-wf___ru"
  }, {
    path: "/auth/error",
    component: _7f82f942,
    name: "auth-error___ru"
  }, {
    path: "/auth/success",
    component: _398b5d86,
    name: "auth-success___ru"
  }, {
    path: "/be/3f4g5q6",
    component: _a6512074,
    name: "3f4g5q6___be"
  }, {
    path: "/be/4wg8a",
    component: _3a2ebbdf,
    name: "4wg8a___be"
  }, {
    path: "/be/adblock",
    component: _3c1f5839,
    name: "adblock___be"
  }, {
    path: "/be/app",
    component: _304c07e0,
    name: "app___be"
  }, {
    path: "/be/articles",
    component: _f8e8e63e,
    name: "articles___be"
  }, {
    path: "/be/catalog",
    component: _3f08e30b,
    name: "catalog___be"
  }, {
    path: "/be/chat",
    component: _26170cf9,
    name: "chat___be"
  }, {
    path: "/be/common",
    component: _35f8f868,
    name: "common___be"
  }, {
    path: "/be/copyright",
    component: _2ac73576,
    name: "copyright___be"
  }, {
    path: "/be/dobro1",
    component: _3d73bde1,
    name: "dobro1___be"
  }, {
    path: "/be/donate",
    component: _62124cd3,
    name: "donate___be"
  }, {
    path: "/be/donate-paw",
    component: _c6328a28,
    name: "donate-paw___be"
  }, {
    path: "/be/favorites",
    component: _07b24e09,
    name: "favorites___be"
  }, {
    path: "/be/films-4k",
    component: _db9a5f14,
    name: "films-4k___be"
  }, {
    path: "/be/guess",
    component: _09db4b06,
    name: "guess___be"
  }, {
    path: "/be/help",
    component: _bd51a73c,
    name: "help___be"
  }, {
    path: "/be/name",
    component: _1d39f82f,
    name: "name___be"
  }, {
    path: "/be/popular",
    component: _d4c6d570,
    name: "popular___be"
  }, {
    path: "/be/privacy",
    component: _eb3a5312,
    name: "privacy___be"
  }, {
    path: "/be/profile",
    component: _24054eca,
    name: "profile___be"
  }, {
    path: "/be/promocodes",
    component: _6e8e3fd8,
    name: "promocodes___be"
  }, {
    path: "/be/signin",
    component: _6b621603,
    name: "signin___be"
  }, {
    path: "/be/sync",
    component: _3cb33abc,
    name: "sync___be"
  }, {
    path: "/be/tag",
    component: _2c388e68,
    name: "tag___be"
  }, {
    path: "/be/termsofuse",
    component: _af6834ec,
    name: "termsofuse___be"
  }, {
    path: "/be/unsubscribe",
    component: _8391e480,
    name: "unsubscribe___be"
  }, {
    path: "/be/vmeste",
    component: _5f07707a,
    name: "vmeste___be"
  }, {
    path: "/be/vpn1",
    component: _d503d644,
    name: "vpn1___be"
  }, {
    path: "/dobro1/success",
    component: _52c3d2d2,
    name: "dobro1-success___ru"
  }, {
    path: "/donate-paw/fail",
    component: _8ebf4d12,
    name: "donate-paw-fail___ru"
  }, {
    path: "/donate-paw/success",
    component: _17de35e0,
    name: "donate-paw-success___ru"
  }, {
    path: "/en/3f4g5q6",
    component: _a6512074,
    name: "3f4g5q6___en"
  }, {
    path: "/en/4wg8a",
    component: _3a2ebbdf,
    name: "4wg8a___en"
  }, {
    path: "/en/adblock",
    component: _3c1f5839,
    name: "adblock___en"
  }, {
    path: "/en/app",
    component: _304c07e0,
    name: "app___en"
  }, {
    path: "/en/articles",
    component: _f8e8e63e,
    name: "articles___en"
  }, {
    path: "/en/catalog",
    component: _3f08e30b,
    name: "catalog___en"
  }, {
    path: "/en/chat",
    component: _26170cf9,
    name: "chat___en"
  }, {
    path: "/en/common",
    component: _35f8f868,
    name: "common___en"
  }, {
    path: "/en/copyright",
    component: _2ac73576,
    name: "copyright___en"
  }, {
    path: "/en/dobro1",
    component: _3d73bde1,
    name: "dobro1___en"
  }, {
    path: "/en/donate",
    component: _62124cd3,
    name: "donate___en"
  }, {
    path: "/en/donate-paw",
    component: _c6328a28,
    name: "donate-paw___en"
  }, {
    path: "/en/favorites",
    component: _07b24e09,
    name: "favorites___en"
  }, {
    path: "/en/films-4k",
    component: _db9a5f14,
    name: "films-4k___en"
  }, {
    path: "/en/guess",
    component: _09db4b06,
    name: "guess___en"
  }, {
    path: "/en/help",
    component: _bd51a73c,
    name: "help___en"
  }, {
    path: "/en/name",
    component: _1d39f82f,
    name: "name___en"
  }, {
    path: "/en/popular",
    component: _d4c6d570,
    name: "popular___en"
  }, {
    path: "/en/privacy",
    component: _eb3a5312,
    name: "privacy___en"
  }, {
    path: "/en/profile",
    component: _24054eca,
    name: "profile___en"
  }, {
    path: "/en/promocodes",
    component: _6e8e3fd8,
    name: "promocodes___en"
  }, {
    path: "/en/signin",
    component: _6b621603,
    name: "signin___en"
  }, {
    path: "/en/sync",
    component: _3cb33abc,
    name: "sync___en"
  }, {
    path: "/en/tag",
    component: _2c388e68,
    name: "tag___en"
  }, {
    path: "/en/termsofuse",
    component: _af6834ec,
    name: "termsofuse___en"
  }, {
    path: "/en/unsubscribe",
    component: _8391e480,
    name: "unsubscribe___en"
  }, {
    path: "/en/vmeste",
    component: _5f07707a,
    name: "vmeste___en"
  }, {
    path: "/en/vpn1",
    component: _d503d644,
    name: "vpn1___en"
  }, {
    path: "/profile/activity",
    component: _6d76d656,
    name: "profile-activity___ru"
  }, {
    path: "/profile/articles",
    component: _142722f8,
    name: "profile-articles___ru"
  }, {
    path: "/profile/favorites",
    component: _4e8acf20,
    name: "profile-favorites___ru"
  }, {
    path: "/profile/friendly",
    component: _27ec9df2,
    name: "profile-friendly___ru"
  }, {
    path: "/profile/history",
    component: _67e59b1d,
    name: "profile-history___ru"
  }, {
    path: "/profile/notifi",
    component: _61749540,
    name: "profile-notifi___ru"
  }, {
    path: "/profile/ref",
    component: _d5e40fc8,
    name: "profile-ref___ru"
  }, {
    path: "/uk/3f4g5q6",
    component: _a6512074,
    name: "3f4g5q6___uk"
  }, {
    path: "/uk/4wg8a",
    component: _3a2ebbdf,
    name: "4wg8a___uk"
  }, {
    path: "/uk/adblock",
    component: _3c1f5839,
    name: "adblock___uk"
  }, {
    path: "/uk/app",
    component: _304c07e0,
    name: "app___uk"
  }, {
    path: "/uk/articles",
    component: _f8e8e63e,
    name: "articles___uk"
  }, {
    path: "/uk/catalog",
    component: _3f08e30b,
    name: "catalog___uk"
  }, {
    path: "/uk/chat",
    component: _26170cf9,
    name: "chat___uk"
  }, {
    path: "/uk/common",
    component: _35f8f868,
    name: "common___uk"
  }, {
    path: "/uk/copyright",
    component: _2ac73576,
    name: "copyright___uk"
  }, {
    path: "/uk/dobro1",
    component: _3d73bde1,
    name: "dobro1___uk"
  }, {
    path: "/uk/donate",
    component: _62124cd3,
    name: "donate___uk"
  }, {
    path: "/uk/donate-paw",
    component: _c6328a28,
    name: "donate-paw___uk"
  }, {
    path: "/uk/favorites",
    component: _07b24e09,
    name: "favorites___uk"
  }, {
    path: "/uk/films-4k",
    component: _db9a5f14,
    name: "films-4k___uk"
  }, {
    path: "/uk/guess",
    component: _09db4b06,
    name: "guess___uk"
  }, {
    path: "/uk/help",
    component: _bd51a73c,
    name: "help___uk"
  }, {
    path: "/uk/name",
    component: _1d39f82f,
    name: "name___uk"
  }, {
    path: "/uk/popular",
    component: _d4c6d570,
    name: "popular___uk"
  }, {
    path: "/uk/privacy",
    component: _eb3a5312,
    name: "privacy___uk"
  }, {
    path: "/uk/profile",
    component: _24054eca,
    name: "profile___uk"
  }, {
    path: "/uk/promocodes",
    component: _6e8e3fd8,
    name: "promocodes___uk"
  }, {
    path: "/uk/signin",
    component: _6b621603,
    name: "signin___uk"
  }, {
    path: "/uk/sync",
    component: _3cb33abc,
    name: "sync___uk"
  }, {
    path: "/uk/tag",
    component: _2c388e68,
    name: "tag___uk"
  }, {
    path: "/uk/termsofuse",
    component: _af6834ec,
    name: "termsofuse___uk"
  }, {
    path: "/uk/unsubscribe",
    component: _8391e480,
    name: "unsubscribe___uk"
  }, {
    path: "/uk/vmeste",
    component: _5f07707a,
    name: "vmeste___uk"
  }, {
    path: "/uk/vpn1",
    component: _d503d644,
    name: "vpn1___uk"
  }, {
    path: "/be/3f4g5q6/guess",
    component: _b00b2b6a,
    name: "3f4g5q6-guess___be"
  }, {
    path: "/be/4wg8a/articles",
    component: _c589d680,
    name: "4wg8a-articles___be"
  }, {
    path: "/be/4wg8a/chat",
    component: _ead2844a,
    name: "4wg8a-chat___be"
  }, {
    path: "/be/4wg8a/comments",
    component: _37b12612,
    name: "4wg8a-comments___be"
  }, {
    path: "/be/4wg8a/desc",
    component: _718ce494,
    name: "4wg8a-desc___be"
  }, {
    path: "/be/4wg8a/donate",
    component: _11c3c2b2,
    name: "4wg8a-donate___be"
  }, {
    path: "/be/4wg8a/feedback",
    component: _4cd24a08,
    name: "4wg8a-feedback___be"
  }, {
    path: "/be/4wg8a/kino",
    component: _146eeaa2,
    name: "4wg8a-kino___be"
  }, {
    path: "/be/4wg8a/streamers",
    component: _25213073,
    name: "4wg8a-streamers___be"
  }, {
    path: "/be/4wg8a/unsubs",
    component: _7c5f33e2,
    name: "4wg8a-unsubs___be"
  }, {
    path: "/be/4wg8a/vmeste",
    component: _27aa6f99,
    name: "4wg8a-vmeste___be"
  }, {
    path: "/be/4wg8a/wf",
    component: _4c6da492,
    name: "4wg8a-wf___be"
  }, {
    path: "/be/auth/error",
    component: _7f82f942,
    name: "auth-error___be"
  }, {
    path: "/be/auth/success",
    component: _398b5d86,
    name: "auth-success___be"
  }, {
    path: "/be/dobro1/success",
    component: _52c3d2d2,
    name: "dobro1-success___be"
  }, {
    path: "/be/donate-paw/fail",
    component: _8ebf4d12,
    name: "donate-paw-fail___be"
  }, {
    path: "/be/donate-paw/success",
    component: _17de35e0,
    name: "donate-paw-success___be"
  }, {
    path: "/be/profile/activity",
    component: _6d76d656,
    name: "profile-activity___be"
  }, {
    path: "/be/profile/articles",
    component: _142722f8,
    name: "profile-articles___be"
  }, {
    path: "/be/profile/favorites",
    component: _4e8acf20,
    name: "profile-favorites___be"
  }, {
    path: "/be/profile/friendly",
    component: _27ec9df2,
    name: "profile-friendly___be"
  }, {
    path: "/be/profile/history",
    component: _67e59b1d,
    name: "profile-history___be"
  }, {
    path: "/be/profile/notifi",
    component: _61749540,
    name: "profile-notifi___be"
  }, {
    path: "/be/profile/ref",
    component: _d5e40fc8,
    name: "profile-ref___be"
  }, {
    path: "/en/3f4g5q6/guess",
    component: _b00b2b6a,
    name: "3f4g5q6-guess___en"
  }, {
    path: "/en/4wg8a/articles",
    component: _c589d680,
    name: "4wg8a-articles___en"
  }, {
    path: "/en/4wg8a/chat",
    component: _ead2844a,
    name: "4wg8a-chat___en"
  }, {
    path: "/en/4wg8a/comments",
    component: _37b12612,
    name: "4wg8a-comments___en"
  }, {
    path: "/en/4wg8a/desc",
    component: _718ce494,
    name: "4wg8a-desc___en"
  }, {
    path: "/en/4wg8a/donate",
    component: _11c3c2b2,
    name: "4wg8a-donate___en"
  }, {
    path: "/en/4wg8a/feedback",
    component: _4cd24a08,
    name: "4wg8a-feedback___en"
  }, {
    path: "/en/4wg8a/kino",
    component: _146eeaa2,
    name: "4wg8a-kino___en"
  }, {
    path: "/en/4wg8a/streamers",
    component: _25213073,
    name: "4wg8a-streamers___en"
  }, {
    path: "/en/4wg8a/unsubs",
    component: _7c5f33e2,
    name: "4wg8a-unsubs___en"
  }, {
    path: "/en/4wg8a/vmeste",
    component: _27aa6f99,
    name: "4wg8a-vmeste___en"
  }, {
    path: "/en/4wg8a/wf",
    component: _4c6da492,
    name: "4wg8a-wf___en"
  }, {
    path: "/en/auth/error",
    component: _7f82f942,
    name: "auth-error___en"
  }, {
    path: "/en/auth/success",
    component: _398b5d86,
    name: "auth-success___en"
  }, {
    path: "/en/dobro1/success",
    component: _52c3d2d2,
    name: "dobro1-success___en"
  }, {
    path: "/en/donate-paw/fail",
    component: _8ebf4d12,
    name: "donate-paw-fail___en"
  }, {
    path: "/en/donate-paw/success",
    component: _17de35e0,
    name: "donate-paw-success___en"
  }, {
    path: "/en/profile/activity",
    component: _6d76d656,
    name: "profile-activity___en"
  }, {
    path: "/en/profile/articles",
    component: _142722f8,
    name: "profile-articles___en"
  }, {
    path: "/en/profile/favorites",
    component: _4e8acf20,
    name: "profile-favorites___en"
  }, {
    path: "/en/profile/friendly",
    component: _27ec9df2,
    name: "profile-friendly___en"
  }, {
    path: "/en/profile/history",
    component: _67e59b1d,
    name: "profile-history___en"
  }, {
    path: "/en/profile/notifi",
    component: _61749540,
    name: "profile-notifi___en"
  }, {
    path: "/en/profile/ref",
    component: _d5e40fc8,
    name: "profile-ref___en"
  }, {
    path: "/uk/3f4g5q6/guess",
    component: _b00b2b6a,
    name: "3f4g5q6-guess___uk"
  }, {
    path: "/uk/4wg8a/articles",
    component: _c589d680,
    name: "4wg8a-articles___uk"
  }, {
    path: "/uk/4wg8a/chat",
    component: _ead2844a,
    name: "4wg8a-chat___uk"
  }, {
    path: "/uk/4wg8a/comments",
    component: _37b12612,
    name: "4wg8a-comments___uk"
  }, {
    path: "/uk/4wg8a/desc",
    component: _718ce494,
    name: "4wg8a-desc___uk"
  }, {
    path: "/uk/4wg8a/donate",
    component: _11c3c2b2,
    name: "4wg8a-donate___uk"
  }, {
    path: "/uk/4wg8a/feedback",
    component: _4cd24a08,
    name: "4wg8a-feedback___uk"
  }, {
    path: "/uk/4wg8a/kino",
    component: _146eeaa2,
    name: "4wg8a-kino___uk"
  }, {
    path: "/uk/4wg8a/streamers",
    component: _25213073,
    name: "4wg8a-streamers___uk"
  }, {
    path: "/uk/4wg8a/unsubs",
    component: _7c5f33e2,
    name: "4wg8a-unsubs___uk"
  }, {
    path: "/uk/4wg8a/vmeste",
    component: _27aa6f99,
    name: "4wg8a-vmeste___uk"
  }, {
    path: "/uk/4wg8a/wf",
    component: _4c6da492,
    name: "4wg8a-wf___uk"
  }, {
    path: "/uk/auth/error",
    component: _7f82f942,
    name: "auth-error___uk"
  }, {
    path: "/uk/auth/success",
    component: _398b5d86,
    name: "auth-success___uk"
  }, {
    path: "/uk/dobro1/success",
    component: _52c3d2d2,
    name: "dobro1-success___uk"
  }, {
    path: "/uk/donate-paw/fail",
    component: _8ebf4d12,
    name: "donate-paw-fail___uk"
  }, {
    path: "/uk/donate-paw/success",
    component: _17de35e0,
    name: "donate-paw-success___uk"
  }, {
    path: "/uk/profile/activity",
    component: _6d76d656,
    name: "profile-activity___uk"
  }, {
    path: "/uk/profile/articles",
    component: _142722f8,
    name: "profile-articles___uk"
  }, {
    path: "/uk/profile/favorites",
    component: _4e8acf20,
    name: "profile-favorites___uk"
  }, {
    path: "/uk/profile/friendly",
    component: _27ec9df2,
    name: "profile-friendly___uk"
  }, {
    path: "/uk/profile/history",
    component: _67e59b1d,
    name: "profile-history___uk"
  }, {
    path: "/uk/profile/notifi",
    component: _61749540,
    name: "profile-notifi___uk"
  }, {
    path: "/uk/profile/ref",
    component: _d5e40fc8,
    name: "profile-ref___uk"
  }, {
    path: "/",
    component: _0eb85081,
    name: "index___ru"
  }, {
    path: "/be/articles/:id",
    component: _03bd6168,
    name: "articles-id___be"
  }, {
    path: "/be/catalog/:id",
    component: _71538076,
    name: "catalog-id___be"
  }, {
    path: "/be/favorites/:id",
    component: _1343829e,
    name: "favorites-id___be"
  }, {
    path: "/be/name/:id",
    component: _0e2538cc,
    name: "name-id___be"
  }, {
    path: "/be/tag/:id",
    component: _4c617e34,
    name: "tag-id___be"
  }, {
    path: "/be/vmeste/:id",
    component: _2b5fbe65,
    name: "vmeste-id___be"
  }, {
    path: "/en/articles/:id",
    component: _03bd6168,
    name: "articles-id___en"
  }, {
    path: "/en/catalog/:id",
    component: _71538076,
    name: "catalog-id___en"
  }, {
    path: "/en/favorites/:id",
    component: _1343829e,
    name: "favorites-id___en"
  }, {
    path: "/en/name/:id",
    component: _0e2538cc,
    name: "name-id___en"
  }, {
    path: "/en/tag/:id",
    component: _4c617e34,
    name: "tag-id___en"
  }, {
    path: "/en/vmeste/:id",
    component: _2b5fbe65,
    name: "vmeste-id___en"
  }, {
    path: "/uk/articles/:id",
    component: _03bd6168,
    name: "articles-id___uk"
  }, {
    path: "/uk/catalog/:id",
    component: _71538076,
    name: "catalog-id___uk"
  }, {
    path: "/uk/favorites/:id",
    component: _1343829e,
    name: "favorites-id___uk"
  }, {
    path: "/uk/name/:id",
    component: _0e2538cc,
    name: "name-id___uk"
  }, {
    path: "/uk/tag/:id",
    component: _4c617e34,
    name: "tag-id___uk"
  }, {
    path: "/uk/vmeste/:id",
    component: _2b5fbe65,
    name: "vmeste-id___uk"
  }, {
    path: "/be/catalog/:id/:section",
    component: _32de64b5,
    name: "catalog-id-section___be"
  }, {
    path: "/en/catalog/:id/:section",
    component: _32de64b5,
    name: "catalog-id-section___en"
  }, {
    path: "/uk/catalog/:id/:section",
    component: _32de64b5,
    name: "catalog-id-section___uk"
  }, {
    path: "/articles/:id",
    component: _03bd6168,
    name: "articles-id___ru"
  }, {
    path: "/catalog/:id",
    component: _71538076,
    name: "catalog-id___ru"
  }, {
    path: "/favorites/:id",
    component: _1343829e,
    name: "favorites-id___ru"
  }, {
    path: "/name/:id",
    component: _0e2538cc,
    name: "name-id___ru"
  }, {
    path: "/tag/:id",
    component: _4c617e34,
    name: "tag-id___ru"
  }, {
    path: "/vmeste/:id",
    component: _2b5fbe65,
    name: "vmeste-id___ru"
  }, {
    path: "/catalog/:id/:section",
    component: _32de64b5,
    name: "catalog-id-section___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
