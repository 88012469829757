export default {
  watch: `Смотреть`,
  follow: `Следить`,
  followed: `Следите`,
  welcome: `Добро пожаловать`,
  dating: `Знакомства`,
  indexh1: `Как смотреть кино и YouTube вместе с другом одновременно на расстоянии`,
  indexmanualdesc: `Сегодня посмотреть любимое кино (фильм, сериал или дораму) онлайн вместе с другом на расстоянии проще простого, у нас!
  Пара кликов - все что нужно для создания своей комнаты + делимся ссылкой на нее с другом! Все просто.
  Время таймлайна видео всех участников комнаты автоматически ежесекундно синхронизируется с её владельцем.
  К тому же каждый может самостоятельно управлять плеером: плей, пауза, изменение времени, громкость и качество.`,
  indexmanualstep1: `Ищем кино, которое хотим посмотреть`,
  indexmanualstep1p: `Начните вводить название фильма в поле выше`,
  indexmanualstep2: `Выбираем из выпадающего списка кино, которое нам подходит`,
  indexmanualstep3: `Вот и все! Создание комнаты завершено. Теперь самое главное - приглашаем своих друзей!
  И помни U Not Alone (ты не одинок)`,
  indexrecommends: `Чаще всего смотрят вместе`,
  indexdesch2: `Смотрите фильмы, сериалы и youtube вместе с друзьями онлайн`,
  indexdesch2p: `Создавайте свои кинокомнаты и расширяйте возможности совместного просмотра`,
  indexdescp1: `Наш сервис внедряет инновационные функции, чтобы сделать ваш опыт совместного просмотра более интересным и удобным. Теперь вы можете создавать свои собственные кинокомнаты, приглашая друзей и делая просмотр еще более персональным. Каждая комната становится уникальным цифровым пространством, где вы можете обсуждать фильмы, делиться впечатлениями и создавать свои собственные тематические сессии.`,
  indexdescp2: `А чтобы расширить ваш выбор контента, мы добавили возможность синхронного просмотра видеороликов с YouTube. Теперь вы и ваши друзья можете не только наслаждаться фильмами и сериалами, но и совместно развлекаться, смотря разнообразные видеоролики на популярной платформе. Это открывает новые горизонты для общения и развлечений в виртуальном кинозале.`,
  indexdescp3: `UNotAlone становится не просто платформой для совместного просмотра, а целым сообществом, где каждый может создать свое уникальное пространство для общения, делиться контентом и наслаждаться разнообразием кинематографии и видеоинформации вместе с друзьями.`,
  indexheadtitle: `Совместный просмотр фильмов, сериалов и youtube вместе с друзьями онлайн (UNA)`,
  indexheaddesc: `UNotAlone – платформа, позволяющая вам смотреть фильмы и сериалы онлайн в режиме реального времени с вашими друзьями, несмотря на расстояние. Пригласите своих близких в виртуальный кинозал, где вы сможете синхронно наслаждаться просмотром и обсуждать контент вместе.`,
  indexfigcaption: `Совместный просмотр кино вместе с друзьями`,
  signIn: `Войти`,
  mainmenu_profile: `Профиль`,
  mainmenu_home: `Домой`,
  mainmenu_rooms: `Комнаты`,
  mainmenu_catalog: `Каталог кино`,
  mainmenu_movies: `Кино`,
  mainmenu_movies4k: `Фильмы в 4K`,
  mainmenu_top100: `ТОП 100 фильмов`,
  mainmenu_chat: `Знакомства`,
  popular_h1: `ТОП 100 лучшего кино в этом месяце`,
  show_more: `Показать еще`,
  popular_desc: `Добро пожаловать на страницу "ТОП 100 лучшего кино" этого месяца! Здесь вы найдете обзоры и рекомендации наиболее захватывающих и увлекательных фильмов, которые вышли в прокат в последнее время. Наша команда экспертов тщательно отбирает фильмы, чтобы предоставить вам список самых привлекательных кинематографических шедевров, которые вы не захотите пропустить.
  Мы стремимся предложить разнообразие жанров, чтобы удовлетворить вкусы каждого зрителя. Независимо от того, любите ли вы драмы, комедии, фантастику, ужасы, приключения или другие жанры, вы обязательно найдете что-то интересное на нашей странице. Мы также учитываем различные страны производства и фильмы разных форматов, чтобы предложить вам многообразие вариантов.
  Каждый фильм, попавший в наш ТОП 100, снабжен кратким обзором, который поможет вам понять его сюжет и основные характеристики. Мы также предоставляем информацию о режиссере, актерском составе и рейтинге фильма, чтобы помочь вам сделать информированный выбор.
  Не важно, являетесь ли вы киноманом, ищете хороший фильм на вечер или интересуетесь новыми кинотрендами, наша страница "ТОП 100 лучшего кино" предлагает вам полное погружение в мир кинематографии. У нас есть что-то для каждого, и мы надеемся, что наши рекомендации помогут вам открыть новые кинематографические шедевры и насладиться прекрасным кино в этом месяце.
  Не теряйте время и присоединяйтесь к нам на странице "ТОП 100 лучшего кино"!`,
  breadcrumbs_home: `Домой`,
  breadcrumbs_catalog: `Каталог кино`,
  breadcrumbs_popular: `Рекомендуем посмотреть`,
  button_create_room: `Создать комнату`,
  search_kino: `Какое кино ищем?`,
  search_what_watch: `Что будем смотреть?`,
  search_input_youtube: `Ссылка на ролик Youtube`,
  leftside_rooms: `Активные комнаты`,
  leftside_total: `Всего`,
  leftside_group: `Группировать`,
  leftside_ungroup: `Разгруппировать`,
  leftside_rooms_opened: `Открытые`,
  leftside_rooms_public: `Общие`,
  leftside_rooms_my: `Мои`,
  leftside_rooms_all: `Все`,
  leftside_rooms_copy: `Клонировать`,
  leftside_rooms_public_room: `Общая комната`,
  leftside_rooms_your_room: `Ваша комната`,
  leftside_rooms_viewers: `Участники`,
  advert_off: `Убрать рекламу`,
  help: `Помощь`,
  social: `Мы в соц.сетях`,
  notfound: `Ничего не найдено`,
  stats_newbie: `Новенькие`,
  stats_activists: `Самые активные`,
  stats_watchers: `Смотрители`,
  stats_newreg: `Зарегистрировались сегодня`,
  stats_how: `Как сюда попасть?`,
  stats_kinomans: `Киноманы, получается...`,
  befriends: `Дружить`,
  signin_h1: `Войдите на сайт и смотрите UNotAlone бесплатно`,
  signin_privacy: `Политики конфиденциальности`,
  signin_termsofuse: `Пользовательского соглашения`,
  signin_terms: `Авторизируясь на сайте вы принимаете условия`,
  signin_and: `и`,
  signin_subscribe: `Я хочу получать рассылки и специальные предложения`,
  signin_soc: `Используйте для входа`,
  signin_premiers: `Эксклюзивные премьеры`,
  signin_premiers_p: `Оригинальные фильмы и сериалы подобранные специально для участников UNotAlone (UNA).`,
  signin_quality: `Отличное качество видео и звука`,
  signin_quality_p: `Качество HD 720 и Full HD 1080, объемный звук Dolby 5.1 для максимального удовольствия от просмотра.`,
  signin_devices: `Работа на всех устройствах`,
  signin_devices_p: `Cмотрите бесплатно где угодно — на Smart TV, в приложениях для iOS и Android.`,
  signin_head_title: `Вход на сайт - UNotAlone (UNA)`,
  fav_to: `Закладки`,
  fav_remove: `Убрать`,
  catalog: `Весь каталог`,
  'tv-show': `ТВ-Шоу`,
  movies: `Фильмы`,
  films: `Фильмы`,
  serials: `Сериалы`,
  anime: `Аниме`,
  'anime-serials': `Многосерийные аниме`,
  genre: `Жанр`,
  country: `Страна`,
  year: `Год`,
  actions: `Боевики`,
  comedies: `Комедии`,
  fantasys: `Фантастика`,
  loves: `Про любовь`,
  horrors: `Ужасы`,
  filter_reset: `Сбросить фильтр`,
  filter_random: `Сортировка`,
  filter_popular: `По популярности`,
  filter_new: `По новизне`,
  filter_rating: `По рейтингу`,
  chatpage_h1: `Знакомства онлайн. Общий чат`,
  chatpage_head_title: `💬 Бесплатный чат для онлайн знакомств`,
  chatpage_head_description: `Общайтесь онлайн со своими друзьями, знакомьтесь с новыми в нашем чате бесплатно!`,
  close: `Закрыть`,
  share: `Поделиться`,
  invite: `Пригласить`,
  chat_invite_p: `На момент приглашения, друг должен находиться на сайте в одной из комнат просмотра или в комнате "Знакомства", 
  иначе уведомление будет отправлено в ТГ.`,
  chat_friendlist_empty: `Ваш список Друзей пуст.`,
  chat_start_chat1: `Начните знакомиться`,
  chat_start_chat2: `прямо сейчас. Играйте в Знакомства или найдите своего друга по имени.`,
  friends_delete: `Удалить из друзей`,
  pm: `Личное сообшение`,
  chat_commands: `Видишь её? А она тебя видит! Нажми и раскрой доп.возможности общения в чате!`,
  chat_banned: `Вам запрещен доступ к чату`,
  chat_message_edit: `Редактирование сообщения`,
  chat_message_quote: `Цитата`,
  chat_views_share: `Поделиться просмотрами`,
  chat_attach_file: `Прикрепить файл`,
  chat_throw_snowball: `Кинуть снежок`,
  to_answer: `Ответить`,
  to_edit: `Редактировать`,
  to_delete: `Удалить`,
  chat_unban: `Разблокировать`,
  chat_ban: `Заблокировать`,
  chat_write_to_you: `Вам пишет`,
  chat_you_write: `Вы пишите`,
  chat_admin: `Адм`,
  chat_bot: `Бот`,
  chat_input_you_message: `Ваше сообщение...`,
  chat_input_pm: `Личное сообщение`,
  chat_input_voicemode: `Режим голосового чата`,
  search_friend_name: `Поиск друга по имени...`,
  room_creating: `Создание комнаты...`,
  owner: 'Владелец',
  pinned: `Закрепить`,
  unpinned: `Открепить`,
  start_watch: `Начать просмотр`,
  minimize: `Свернуть`,
  chat_minimize: `Убрать чат`,
  controls: `Управление`,
  history: `История`,
  chat_clear: `Очистить чат`,
  delete: `Удалить`,
  download: `Скачать`,
  room: `комната`,
  room_private: `Приватная`,
  room_public: `Открытая`,
  translation: `Озвучка`,
  season: `Сезон`,
  episode: `Серия`,
  prev: `Пред.`,
  next: `След.`,
  room_change_film: `Сменить кино в комнате на...`,
  player_default: `Стандартный плеер`,
  apply: `Применить`,
  watch_with: `Смотреть с..`,
  videofile_link: `Ссылка на видеофайл`,
  hide: `Скрыть`,
  show: `Показать`,
  player_not_work: `Плеер не работает?! Подробнее...`,
  click: `Клик`,
  get_link_videofile: `Вы можете самостоятельно получить ссылку на видеофайл и вставить ее в поле ниже.`,
  get_link: `Получить ссылку`,
  breadcrumbs_movie4k: `Фильмы в 4K`,
  movie4k_h1: `Фильмы и сериалы в 4k`,
  movie4k_desc: `На нашей странице вы можете наслаждаться просмотром фильмов и сериалов в высоком качестве 4K.
  Мы предлагаем широкий выбор контента, включая новинки и классические фильмы, а также популярные сериалы.
  Все материалы доступны для просмотра в лучшем качестве, что позволяет наслаждаться каждой деталью и ощущать полную глубину изображения.
  Наша страница постоянно обновляется, чтобы предоставлять вам самые свежие и интересные фильмы и сериалы.
  Вы можете выбрать любой жанр, от комедий до драм и фантастики, и наслаждаться просмотром в удобное для вас время.
  Мы гарантируем высокое качество изображения и звука, а также удобный интерфейс для поиска и просмотра контента.
  Начните свой кинематографический опыт в 4K качестве на нашей странице с фильмами и сериалами!`,
  movie4k_head_title: `Смотреть фильмы и сериалы в 4к качестве бесплатно`,
  movie4k_head_description: `Смотрите онлайн лучшие фильмы и сериалы в 4k качестве 2160р бесплатно у нас.`,
  popular_head_title: `ТОП 100 лучшего кино`,
  popular_head_description: `Рекомендуем к просмотру с другом фильмы и сериалы - выбор наших пользователей в`,
  menu_profile_ecomode: `Эн.сбер. режим`,
  menu_profile_active: `Активировать`,
  menu_profile_premium_loss: `заканчивается`,
  cancel: `Отменить`,
  change: `Изменить`,
  nickname_firstchange_free: `Первая смена бесплатно, далее 10 Монет.`,
  watch_with_ads: `Смотреть с рекламой`,
  yes: `Да`,
  no: `Нет`,
  views_without_ads: `Просмотры без рекламы`,
  views_without_ads_loss: `Просмотры без рекламы закончились (+пополнить)`,
  profile_coins: `Монеты`,
  wallet: `Кошелек`,
  my_room: `Моя комната`,
  watch_history: `История просмотров`,
  friendship: `Дружба`,
  favorite: `Избранное`,
  referrals: `Рефералы`,
  activity: `Активность`,
  notifications: `Уведомления`,
  logout: `Выйти`,
  install_app: `Установить приложение`,
  newname: `Новое имя`,
  hello_h1: `Узнай нас лучше!`,
  hello_firststep: `Регистрация это только первый шаг.`,
  hello_learn: `Изучите широкие возможности нашего сервиса и вы поймете, что это именно то что вы искали.`,
  hello_follow_vk: `Присоединяйтесь к нам ВКонтакте и получите Premium`,
  hello_follow_tg: `Присоединяйтесь к нам в Телеграм и получите Premium`,
  hello_follow_tg_p1: `Узнайте свой ID в Телеграм y нашего`,
  hello_follow_tg_p2: `отправив сообщение`,
  hello_follow_tg_bot: `бота`,
  hello_use: `Используйте полученные Монеты`,
  hello_use_p: `Заработанные или полученные в знак благодарности за пожертвования Монеты, вы можете использовать для подключения`,
  hello_21h: `Приходите на киносеансы в 21:00`,
  hello_21h_p: `Ежедневно создается для всех участников сайта. Начало просмотра в 21:00 по МСК.
  Время синхронизируется по серверу, лучше не опаздывать! Приходите сами и приводите друзей, общайтесь и получайте подарки просто за проведенное время вместе.`,
  hello_invite: `Некого пригласить и не с кем посмотреть фильм?`,
  hello_invite_dating: `Знакомьтесь на нашем сайте`,
  hello_invite_p: `Смотрите анкеты пользователей
  и добавляй их в друзья. И не забудь заполнить анкету.
  Все анкеты проходят модерацию, отнеситесь ответственно. Чем подробнее анкету вы заполните, тем больше шансов
  найти друга.`,
  hello_service_like: `Понравился наш сервис?`,
  hello_service_like_p: `Поддержите нас. Полученные средства идут на оплату оборудования, рекламу и развитие функционала сайта.`,
  go_to_room: `Перейти в комнату`,
  balance: `Баланс`,
  hello_invite_ref_p: `Помогайте нам популяризировать наш сервис, оставляйте свою ссылку
  в соц.группах и чатах и получайте реальные деньги!`,
  tg: `Телеграм`,
  hello_follow_group: `Вступите в нашу группу`,
  check: `Проверить`,
  hello_check_follow: `Проверьте что состоите в группе и получите Монеты`,
  hello_invite_ref: `Приглашайте друзей на сайт по своей реф.ссылке`,
  hello_next_time: `В другой раз`,
  hello_hide: `Больше не показывать`,
  hello_viewspacks: `Пакет просмотров без рекламы`,
  donate: `Поддержать`,
  chat_invite_sended: `Приглашение отправлено`,
  auth_needded: `Необходимо войти на сайт`,
  request_sent: `Запрос отправлен`,
  dont_be_friends: `Вы больше не дружитесь`,
  chat_alon_msg_1: `Вопросы и предложения пишите в`,
  chat_alon_msg_2: `Чтобы добавить друга: 1) Нажмите на его имя в чате, выберите [::b::]Дружить[::/b::].
  2) Ожидайте, когда он примет ваше предложение.
  Откройте свой [::b::]Профиль - Дружба - Запросы[::/b::], чтобы посмотреть входящие и исходящие заявки.`,
  chat_alon_msg_roomlink: `Отправьте другу пригласительную ссылку в комнату!`,
  chat_alon_msg_anime: `Часто смотрите аниме? Рекомендуем`,
  premium_expired: `Срок вашей Premium подписки истек.`,
  premium_update: `Обновить подписку`,
  chat_alon_mgs_premium_update_2: `и продолжить пользоваться сайтом без рекламы?`,
  viewspack_update: `Обновите пакет просмотров`,
  viewspack_runout: `У вас закончились просмотры без рекламы`,
  viewspack_soonrunout: `У вас скоро закончатся просмотры без рекламы`,
  chat_views_were_shared_with_you: `С вами поделились просмотрами`,
  message_sended: `Сообщение отправлено`,
  room_deleted: `Комната удалена`,
  page_reload: `Обновите страницу`,
  chat_messages_flood: `Слишком часто отправляете сообщения`,
  on_balance: `На балансе`,
  coins: `Монет`,
  premium_h2: `Наш сервис полностью бесплатен для вас!`,
  premium_p: `Однако, как и каждый проект, наш нуждается в вашей поддержке. Именно поэтому мы составили для вас комфортные варианты Premium подписок.`,
  premium_tariffs: `Сравнeние тарифных планов`,
  premium_without_sub: `Без Premium`,
  premium_tariffs_faster_website: `Более быстрая работа веб-сайта`,
  premium_tariffs_player_ads: `Реклама в плеере`,
  premium_tariffs_flyfoll: `Flyroll видео реклама`,
  premium_tariffs_banners: `Рекламные баннеры`,
  premium_tariffs_stickers: `Эксклюзивные стикеры`,
  premium_tariffs_stickers_p: `Демон и Ангелица и другие парные стикеры`,
  premium_tariffs_video_quality: `Качество видео`,
  active: `Активировать`,
  premium_on_1month: `На 1 месяц`,
  premium_sub: `подписка`,
  premium_tariffs_with_out_ads: `без рекламы`,
  premium_tariffs_views: `просмотров`,
  ok: `OK`,
  premium_active: `Подключение Premium`,
  premium_active_p: `Кому подключаем подписку?`,
  premium_active_self: `Себе`,
  premium_active_friend: `Другу`,
  premium_views_active: `Подключение Пакета просмотров`,
  premium_views_p: `просмотров без рекламы`,
  premium_tariffs_1000: `Пакет "Беззаботный" - хватит надолго, +1000 просмотров. Вы можете делиться бесплатными просмотрами со всеми участниками комнаты.`,
  premium_tariffs_share: `Можно делиться с участниками комнаты`,
  premium_sub_1: `Если вы цените
  комфорт просмотра без рекламы, повышенную скорость работы сайта и лучшее качество видео,
  вам подойдет Premium подписка.`,
  premium_sub_2: `Обратите внимание. В подписку уже включено определенное количество Просмотров без рекламы.`,
  premium_views_runout_strong: `Если у вас закончились Просмотры без рекламы из подписки или вы просто хотите смотреть кино в Full HD без рекламы в плеере и баннер-реклама вам не мешает,
  на такой случай мы предусмотрели возможность пополнить пакет просмотров отдельно от Premium подписки.`,
  premium_views_runout_p: `При пополнении пакета без активной Premium подписки, баннер-реклама будет отображаться на сайте (кроме плеера).`,
  premium_on_1year: `На 365 дней`,
  page: `Страница`,
  or: `или`,
  app_h1: `Приложение UNotAlone (UNA) на смартфон и ПК`,
  app_link: `ссылке`,
  app_goto_safari: `В браузере Safari перейти по`,
  app_goto_chrome: `В браузере Safari перейти по`,
  app_safari_share: `В нижнем меню нажмите на иконку «Поделиться», а затем выберите «Добавить на экран Домой».`,
  app_safari_add: `Нажмите «Добавить». Иконка появится на экране смартфона.`,
  app_chrome_menu: `В верхнем правом углу браузера откройте Меню, а затем выберите «Установить приложение».`,
  app_chrome_install: `Нажмите «Установить» - «Добавить на домашний экран». Иконка появится на экране смартфона.`,
  app_head_title: `Приложение для совместного просмотра на Android и iOS - UNA`,
  app_head_description: `Рассказываем как установить мобильное приложение для совместного просмотра для Android и iOS - UNA`,
  reviews: `Рецензии`,
  duration: `Продолжительность`,
  rating_kinopoisk: `Рейтинг КиноПоиск`,
  rating_imdb: `Рейтинг IMDb`,
  premiere_ru: `Премьера (РФ)`,
  premiere_world: `Премьера (мир)`,
  directors: `Режиссеры`,
  producers: `Продюсеры`,
  actors: `Актеры`,
  title_alt: `Лицензионное название`,
  about: `Описание`,
  frames: `Кадры`,
  trailers: `Трейлеры`,
  persons: `Персоны`,
  sap: `Сиквелы и приквелы`,
  facts: `Факты и киноляпы`,
  recommends: `Рекомендации`,
  story: `Сюжет`,
  our_review: `Наша рецензия`,
  watch_all_series: `смотреть онлайн все серии бесплатно на русском`,
  watch_free_hd: `смотреть онлайн бесплатно в хорошем HD качестве`,
  film_head_description: `описание, кадры, рецензии и отзывы. Бесплатно в хорошем качестве`,
  catalog_head_title_watch_online: `смотреть онлайн в хорошем качестве бесплатно`,
  catalog_head_title_best_quality_from_us: `лучшего качества в разных озвучках совершенно бесплатно у нас`,
  catalog_head_kino_collection: `Кино: коллекция`,
  watch_online: `смотреть онлайн`,
  clone: 'Клонировать',
}