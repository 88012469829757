<template>
  <div
    v-cloak
    :data-theme="theme"
    :style="`--color:#${themeColor}`"
    :class="{
      'mobile': !isDesktop,
      'premium': user && (user.group === 777 || user.group === 0),
      'minimize': menuMinimize,
      'eco': ecoMode,
    }"
    class="main-layout"
  >
    <Mobile v-if="!isDesktop" />

    <Snow v-if="christmasTime && showSnow" />

    <MovieAds v-if="(!user || user.group === 1)" />
    <!-- <MarketPlace v-if="(!user || user.group === 1)" /> -->

    <!-- <div v-if="isDesktop && (!user || user.group === 1)" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd" style="text-align:right;">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2003630-3"></div>
      </div>
    </div> -->

    <div
      v-if="!$route.name.includes('vmeste') && !$route.name.includes('index')"
      style="position: fixed; left: calc(50% - 110px); text-align:center; z-index: 5;"
      :style="`bottom: ${isDesktop ? '80' : '60'}px;`"
    >
      <button @click="createRoom" class="glow-on-hover" type="button">{{ $t('button_create_room') }}</button>
    </div>

    <div
      v-if="!tabActive"
      class="on-blur"
      style="display:block;position:fixed;top:0px;left:0px;width:100%;height:100%;background-color:rgba(0,0,0,0.55);z-index:10;"
    />
    <nav v-if="isDesktop" class="menu">
      <div class="left">
        <!-- <nuxt-link to="/donate" :class="{ 'active': $route.name.includes('dobro') }">
          <img :src="`/static_files/icons/crown-dark.svg`" alt="">
          Подписки
        </nuxt-link> -->
        <nuxt-link :to="localePath('/catalog')" :class="{ 'active': $route.name.includes('catalog') && $route.query.year != new Date().getFullYear() }">
          <img :src="`/static_files/icons/paper-dark.svg`" alt="">
          <template v-if="!menuMinimize">{{ $t('mainmenu_catalog') }}</template>
        </nuxt-link>
        <nuxt-link :to="localePath(`/catalog?page=1&year=${new Date().getFullYear()}&sort=date`)" :class="{ 'active': !!$route.query.year && $route.query.year == new Date().getFullYear() }">
          <img :src="`/static_files/icons/new.svg?2`" alt="">
          <template v-if="!menuMinimize">{{ $t('mainmenu_movies') }} {{ new Date().getFullYear() }}</template>
        </nuxt-link>
        <nuxt-link :to="localePath(`/films-4k`)" :class="{ 'active': $route.name.includes('films-4k') }">
          <img :src="`/static_files/icons/4k.png?2`" alt="">
          <template v-if="!menuMinimize">{{ $t('mainmenu_movies4k') }}</template>
        </nuxt-link>
        <nuxt-link :to="localePath('/popular')" :class="{ 'active': $route.name.includes('popular') }">
          <img :src="`/static_files/icons/star-dark.svg`" alt="">
          <template v-if="!menuMinimize">{{ $t('mainmenu_top100') }}</template>
        </nuxt-link>
        <nuxt-link :to="localePath('/chat')" :class="{ 'active': $route.name.includes('chat') }">
          <img :src="`/static_files/icons/friends.svg`" alt="">
          <template v-if="!menuMinimize">{{ $t('mainmenu_chat') }}</template>
        </nuxt-link>
        <div
          @click="menuMinimize = !menuMinimize"
          :style="`transform:rotateZ(${menuMinimize?'0':'180'}deg);`"
          class="minimize-btn"
        ></div>
      </div>
      <div class="center">
        <ChristmasLights v-if="christmasTime" />
        <img v-if="christmasTime" src="/static_files/snow/snowman.svg" alt="" style="width:22px;height:22px;">
        <nuxt-link :to="localePath('/')" class="logo" :class="themeColor">
          UNOTALONE
          <!-- <img src="/static_files/icons/free-icon-santa-hat-6262264.png" alt="" style="width:20px;height:20px;position:absolute;right:0px;top:0px;"> -->
        </nuxt-link>
      </div>
      <div v-if="user" class="right">
        <!-- <nuxt-link v-for="locale in availableLocales" :key="locale.code" :to="switchLocalePath(locale.code)" class="locales">
          <img :src="`/static_files/flags/${locale.code}.webp`" alt="">
          {{ locale.name }}
        </nuxt-link> -->
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar-wrap" :class="{ 'premium': user.group === 777 }">
            <!-- <i v-if="user.group === 777" class="crown" :class="{ 'patron': user.patron }" /> -->
            <div class="avatar">
              <img :src="user.photo" :alt="user.name">
              <sup v-if="user.noti.count" class="friendly">{{ user.noti.count }}</sup>
            </div>
          </div>
          <strong>{{ user.name }}</strong>
        </div>
      </div>
      <div v-else class="right">
        <!-- <nuxt-link v-for="locale in availableLocales" :key="locale.code" :to="switchLocalePath(locale.code)" class="locales">
          <img :src="`/static_files/flags/${locale.code}.webp`" alt="">
          {{ locale.name }}
        </nuxt-link> -->
        <nuxt-link :to="localePath(`/signin?ref=${encodeURI($route.fullPath)}`)" class="login">
          {{ $t('signIn') }}
        </nuxt-link>
      </div>
    </nav>
    <nav v-else class="menu">
      <ChristmasLights v-if="christmasTime" />
    <!-- <nav v-else-if="$route.name !== 'vmeste-id'" class="menu"> -->
      <div @click="showLeftSide" :class="{ 'active': roomsList.length }">
        <i v-if="!roomsList.length" class="icon-rooms" />
        <i v-else class="icon-close" />
        <strong>{{ $t('mainmenu_rooms') }}</strong>
      </div>
      <nuxt-link :to="localePath('/chat')" tag="div" :class="{ 'active': $route.name.includes('chat') }">
        <i class="icon-chat" />
        <strong>{{ $t('mainmenu_chat') }}</strong>
      </nuxt-link>
      <nuxt-link :to="localePath('/')" tag="div" :class="{ 'active': $route.name.includes('index') }">
        <img v-if="christmasTime" src="/static_files/snow/snowman.svg" alt="" style="position:relative;top:4px;width:24px;height:24px;">
        <i v-else class="icon-home" />
        <strong>{{ $t('mainmenu_home') }}</strong>
      </nuxt-link>
      <!-- <nuxt-link to="/donate" tag="div" :class="{ 'active': $route.name.includes('dobro') }">
        <i class="icon-crown" />
        <strong>Dobro</strong>
      </nuxt-link> -->
      <div @click="showCatalogMenu = !showCatalogMenu" :class="{ 'active': showCatalogMenu }">
        <i class="icon-catalog" />
        <strong>{{ $t('mainmenu_movies') }}</strong>
      </div>
      <transition name="toTop">
        <div v-if="showCatalogMenu" class="menu-catalog">
          <nuxt-link :to="localePath(`/catalog?page=1&year=${new Date().getFullYear()}&sort=date`)">
            <img :src="`/static_files/icons/new.svg?2`" alt="">
            <strong>{{ $t('mainmenu_movies') }} {{ new Date().getFullYear() }}</strong>
          </nuxt-link>
          <nuxt-link :to="localePath('/films-4k')">
            <img :src="`/static_files/icons/4k.png?2`" alt="">
            <strong>{{ $t('mainmenu_movies4k') }}</strong>
          </nuxt-link>
          <nuxt-link :to="localePath('/popular')">
            <img :src="`/static_files/icons/star-dark.svg`" alt="">
            <strong>{{ $t('mainmenu_top100') }}</strong>
          </nuxt-link>
        </div>
      </transition>

      <!-- <nuxt-link :to="`/catalog?year=${new Date().getFullYear()}&sort=date`" tag="div" :class="{ 'active': $route.name.includes('catalog') }">
        <i class="icon-catalog" />
        <strong>Каталог</strong>
      </nuxt-link> -->
      <!-- <nuxt-link to="/films-4k" tag="div" :class="{ 'active': $route.name.includes('films-4k)' }">
        <i class="icon-films4k" />
        <strong>Фильмы 4k</strong>
      </nuxt-link> -->
      <div v-if="user">
        <div @click="showUserMenu = !showUserMenu" class="auth">
          <div class="avatar-wrap" :class="{ 'premium': user.group === 777 }">
            <!-- <i v-if="user.group === 777" class="crown" :class="{ 'patron': user.patron }" /> -->
            <div class="avatar">
              <img :src="user.photo" :alt="user.name">
              <sup v-if="user.noti.count" class="friendly">{{ user.noti.count }}</sup>
            </div>
          </div>
          <strong>{{ $t('mainmenu_profile') }}</strong>
        </div>
        <!-- <input
          ref="avatarUpload"
          type="file"
          style="display:none;"
          accept="image/gif, image/png, image/jpeg, image/jpg"
          @change="uploadFile($event, 'avatar')"
        > -->
      </div>
      <div v-else>
        <nuxt-link :to="localePath(`/signin?ref=${encodeURI($route.fullPath)}`)">
          <i class="icon-profile" />
          <strong>{{ $t('signIn') }}</strong>
        </nuxt-link>
      </div>
    </nav>

    <div v-if="user && showUserMenu" class="user-menu">
      <div v-if="christmasTime" @click="hideSnow" style="position: relative;cursor:pointer;">
        <img src="/static_files/snow/snowflake.svg" alt="" style="position:absolute;top:0px;left:0px;width:24px;height:24px;">
        <div v-if="showSnow" class="snow-btn" />
      </div>

      <div style="display:grid;grid-template-columns:100px auto;grid-gap:10px;align-items:center;">
        <div>
          <input
            ref="avatarUpload"
            type="file"
            style="display:none;"
            accept="image/gif, image/png, image/jpeg, image/jpg"
            @change="uploadFile($event, 'avatar')"
          >
          <div class="avatar-wrap" :class="{ 'premium': user.group === 777 }">
            <!-- <i v-if="user.group === 777" class="crown" :class="{ 'patron': user.patron }" /> -->
            <div @click="avatarUploadTrigger" class="avatar">
              <img :src="user.photo" alt="">
              <span>{{ $t('change') }}</span>
            </div>
          </div>
        </div>

        <div>
          <input
            :data-user-id="user.id"
            :value="user.id"
            type="text"
            readonly
            style="position:absolute;top:-9999px;opacity:0;"
          />
          <div @click="ecoMode = !ecoMode" class="eco-btn">
            <div class="icon"></div>
            {{ $t('menu_profile_ecomode') }}
          </div>
          <!-- <div style="display:grid;grid-template-columns:auto auto;align-items:center;">


          </div> -->

          <div @click="shareID" style="font-size:1.2rem;color:rgba(255,255,255,0.5);cursor:pointer;">
            <span style="text-transform:uppercase;">ID:</span> {{ user.id }}
          </div>

          <div style="margin:0px 0px 10px;">
            <strong>{{ user.name }}</strong>
            <i @click="showEditName = !showEditName" class="edit" />
          </div>

          <div style="font-size:1.2rem;text-transform:uppercase;cursor:pointer;">
            <nuxt-link :to="localePath('/donate')" tag="span">
              <b
                style="margin-right:5px;padding:0px 6px;color:#000;border-radius:20px;background-color:#fad200;"
              >Premium</b>
              <u v-if="user.group === 777" style="font-weight:500;"> {{ $t('menu_profile_premium_loss') }} {{ user.premium_date | filterDate }}</u>
              <u v-else style="cursor:pointer;font-weight:500;">{{ $t('menu_profile_active') }}</u>
            </nuxt-link>
          </div>
        </div>
      </div>
    
      <div v-if="showEditName">
        <input v-model="newName" type="text" min="3" max="30" :placeholder="$t('newname')">
        <div>
          <button @click="changeName" class="nickname">
            {{ $t('change') }}
          </button>
          <button @click="showEditName = !showEditName" class="nickname">
            {{ $t('cancel') }}
          </button>
        </div>
        <p style="font-size:1.2rem;">{{ $t('nickname_firstchange_free') }}</p>
      </div>

      <div class="row" style="text-align:right;font-size:1.4rem;">
        <!-- <nuxt-link to="/donate#views" tag="span">
        </nuxt-link> -->
        <template v-if="user.views">
          <!-- Приостановить просмотры без рекламы ({{ user.views }}) -->
          {{ $t('watch_with_ads') }}
          <div class="toggle" style="display: inline-block;">
            <input type="checkbox" id="cbx" v-model="user.views_pause" @change="viewsPause" />
            <label for="cbx" style="margin-left:5px;">
              <span>
                <b>{{ user.views_pause ? $t('yes') : $t('no') }}</b>
              </span>
            </label>
          </div>
          <div style="margin-top:5px;">
            {{ $t('views_without_ads') }}: {{ user.views }}
          </div>
        </template>
        <template v-else>
          <nuxt-link :to="localePath('/donate#views')" tag="span">
            <b style="color:lime;">{{ $t('views_without_ads_loss') }}</b>
          </nuxt-link>
        </template>
      </div>
      <div class="row two" style="font-size:1.4rem;">
        <nuxt-link to="/donate" tag="span">
          <b style="color:#fff;">
            <img src="/static_files/icons/coin-donate.svg" alt="" style="display:inline-block;position:relative;top:2px;width:16px;height:16px;">
            {{ $t('profile_coins') }}: {{ user.balance }} <b style="font-weight:600;color:lime;">+</b>
          </b>
        </nuxt-link>
        <nuxt-link :to="localePath('/profile/ref')" tag="span">
          <b style="color:#fff;">
            <img src="/static_files/icons/wallet.svg" alt="" style="display:inline-block;position:relative;top:2px;width:16px;height:16px;">
            {{ $t('wallet') }}: {{ user.wallet }}
          </b>
        </nuxt-link>
      </div>
      <div v-if="user.room" class="row two">
        <nuxt-link :to="localePath(`/vmeste/${user.room._id}`)" tag="span" style="color:lime;">{{ $t('my_room') }}</nuxt-link>
        <div></div>
      </div>
      <div class="row two">
        <nuxt-link :to="localePath('/profile/history')" tag="span">{{ $t('watch_history') }}</nuxt-link>
        <nuxt-link :to="localePath('/profile/friendly')" tag="span">{{ $t('friendship') }}<sup v-if="user.noti.count" class="friendly">{{ user.noti.count }}</sup></nuxt-link>
      </div>
      <div class="row two">
        <nuxt-link :to="localePath('/profile/favorites')" tag="span">{{ $t('favorite') }}</nuxt-link>
        <nuxt-link :to="localePath('/profile/ref')" tag="span">{{ $t('referrals') }}</nuxt-link>
      </div>
      <div class="row two">
        <nuxt-link :to="localePath('/profile/activity')" tag="span">{{ $t('activity') }}</nuxt-link>
        <nuxt-link :to="localePath('/profile/notifi')" tag="span">{{ $t('notifications') }}</nuxt-link>
      </div>
      <div class="row two">
        <nuxt-link :to="localePath('/help')" tag="span">{{ $t('help') }}</nuxt-link>
        <span @click="logout">{{ $t('logout') }}</span>
      </div>

      <div class="msoc">
        <nuxt-link :to="localePath('/app')" class="app">
          <img src="/static_files/icons/smartphone.png" alt="">
          {{ $t('install_app') }}
        </nuxt-link>
        <a href="https://vk.com/unotaloneru" target="_blank">
          <img src="/static_files/social/vkontakte.png" class="vk" alt="">
        </a>
        <a href="https://t.me/unotaloneru" target="_blank">
          <img src="/static_files/social/telegram.png" class="tg" alt="">
        </a>
      </div>

      <div style="margin-top:10px;">
        <nuxt-link
          v-for="locale in availableLocales"
          :key="locale.code"
          :to="switchLocalePath(locale.code)"
          style="display:inline-block;margin:7px 7px 0px 0px;font-size:1rem;"
          class="locales"
        >
          <img :src="`/static_files/flags/${locale.code}.webp`" alt="">
          {{ locale.name }}
        </nuxt-link>
      </div>

      <i @click="showUserMenu = !showUserMenu" class="close" />
    </div>

    <div class="main-wrapper" :class="{'inroom': inRoom}">
      <!-- <transition name="toRight"> -->
      <div v-if="(isDesktop && !menuMinimize) || (!isDesktop && roomsList.length)" class="left-side">
        <div v-show="isDesktop" id="yandex_rtb_R-A-6711067-6" class="rsya-inf" style="margin-bottom:5px;"></div>
        <div class="rooms-add">
          <!-- <strong>Создать комнату</strong> -->
          <SH v-if="!inputyt" :type="`rooms`" />
          <!-- <input v-else type="text" v-model="ytSrc" placeholder="https://youtu.be/Ju86mknumYM"> -->
          <input v-else type="text" v-model="ytSrc" :placeholder="$t('search_input_youtube')">
          <i @click="inputyt = !inputyt" class="source" :class="`${inputyt ? 'youtube' : 'films' }`" />
        </div>
        <div class="rooms-list">
          <strong>{{ $t('leftside_rooms') }}</strong>
          <div>
            <div style="display:grid;grid-template-columns:1fr 1fr;">
              <div style="display:inline-block;font-size:1.2rem;">
                {{ $t('leftside_total') }} {{ roomsCount }} ·
                <span style="cursor:pointer;" @click="roomsGroup = !roomsGroup">
                  {{ !roomsGroup ? $t('leftside_group') : $t('leftside_ungroup') }}
                </span>
                <!-- <template v-if="(roomsCount - roomsList.length)">
                  | Приватные {{ roomsCount - roomsList.length }}
                </template> -->
              </div>
              <div class="sort-rooms">
                <span v-if="!myRooms" @click="filterRooms('all')">{{ $t('leftside_rooms_all') }}</span>
                <template v-if="!myRooms">·</template>
                <span @click="filterRooms('opened')">{{ $t('leftside_rooms_opened') }}</span>
                <template>·</template>
                <span @click="filterRooms('common')">{{ $t('leftside_rooms_public') }}</span>
                <template v-if="user">·</template>
                <span v-if="user" @click="(myRooms = !myRooms)">
                  {{ !myRooms ? $t('leftside_rooms_my') : $t('leftside_rooms_all') }}
                </span>
              </div>
            </div>

            <div v-if="roomsList.length" class="items">
              <ul style="height:100%;">
                <nuxt-link
                  v-for="item in roomsList"
                  :key="`room-${item._id}`"
                  :to="localePath(!item.hidden || (item.owner && user && user.id === item.owner.id) ? `/vmeste/${item._id}` : '')"
                  tag="li"
                  :class="{
                    'private': item.hidden,
                    'active': $route.params.id === item._id,
                    'vmeste': item.vmeste,
                    'common': item.common,
                    'self': item.owner && user && user.id === item.owner.id
                  }"
                  class="item"
                >
                  <div class="poster">
                    <div class="icon-enter" />
                    <img
                      :data-src="filmPoster(item.film)"
                      src="/static_files/no-poster.png"
                      :alt="item.film.title_ru"
                      loading="lazy"
                      class="lazyload"
                    />
                  </div>
                  <div class="title">
                    <nuxt-link :to="localePath(`/catalog/${postUrl(item.kinopoisk_id, item.film.title_ru)}`)">
                      <i v-if="item.hidden" class="icon-private" />
                      {{ ['ru', 'uk', 'be'].includes(lang) ? item.film.title_ru : item.film.title_en || item.film.title_ru }}
                    </nuxt-link>
                    <!-- <span class="en">{{ item.film.title_en }}</span> -->
                    <span class="actions">
                      <nuxt-link :to="localePath(`/vmeste?id=${item.kinopoisk_id}`)">{{ $t('leftside_rooms_copy') }}</nuxt-link>
                      <b v-if="item.vmeste">Сеанс в 21:00</b>
                      <b v-else-if="item.common">{{ $t('leftside_rooms_public_room') }}</b>
                      <b v-else-if="item.hidden"></b>
                      <!-- <b v-else-if="item.hidden">Приватная</b> -->
                      <b v-else-if="user && item.owner && item.owner.id === user.id">{{ $t('leftside_rooms_your_room') }}</b>
                      <b v-else>{{ item.owner ? item.owner.name : $t('leftside_rooms_public_room') }}</b>
                      <template v-if="item.viewers"><b class="online"></b>{{ $t('leftside_rooms_viewers') }}: {{ item.viewers }}</template>
                    </span>
                  </div>
                </nuxt-link>
                <li v-if="(roomsList.length >= 20 && roomsList.length < roomsCount)" class="more">
                  <div></div>
                  <div @click="getRooms(++roomsPage)" class="show-more">
                    <button>
                      <span>{{ $t('show_more') }}</span>
                      <i class="arrow" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div v-else>
            <i class="loader" /> Список загружается...
          </div> -->
        </div>
      </div>

      <div class="content-scroll">
        <template v-if="(!user || user.group === 1)">
          <div v-show="!inRoom" id="yandex_rtb_R-A-6711067-1" class="rsya-block"></div>
          <nuxt-link v-if="!inRoom && (!user || (user.group !== 777 && user.group !== 0))" :to="localePath('/donate')" tag="div" class="close-ad">
            {{ $t('advert_off') }}
          </nuxt-link>
        </template>

        <nuxt />

        <footer v-if="!$route.name.includes('vmeste-id')">
          <div class="links">
            <div class="social">
              <a href="mailto:@support@unotalone.su" target="_blank">support@unotalone.su</a>
              <span style="font-weight:700;margin:0px 4px;"> · </span>
              <nuxt-link :to="localePath('/help')">{{ $t('help') }}</nuxt-link>
              <span style="font-weight:700;margin:0px 4px;"> · </span>
              <strong>{{ $t('social') }}</strong>
              <!-- <a href="https://www.instagram.com/unotaloneru/" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/instagram.png`" alt="">
              </a>
              <a href="https://rutube.ru/channel/29841772/" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/rutube.png`" alt="">
              </a>
              <a href="https://www.youtube.com/@unotalone" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/youtube.png`" alt="">
              </a> -->
              <a href="https://ru.pinterest.com/unotalone_ru/" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/pinterest.png`" alt="">
              </a>
              <a href="https://vk.com/unotaloneru" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/vkontakte.png`" alt="">
              </a>
              <a href="https://t.me/unotaloneru" target="_blank">
                <img class="lazyload" src="/static_files/no-poster.png" :data-src="`/static_files/social/telegram.png`" alt="">
              </a>
            </div>
            <div class="platform">
              <!-- <a href="https://tv.unotalone.ru">
                <img src="/static_files/no-poster.png" :data-src="`/static_files/smart-tv.png`" alt="">
              </a> -->
              <nuxt-link :to="localePath('/app')">
                <img class="lazyload" loading="lazy" src="/static_files/no-poster.png" :data-src="`/static_files/gstore-mini.png`" alt="">
              </nuxt-link>
              <nuxt-link :to="localePath('/app')">
                <img class="lazyload" loading="lazy" src="/static_files/no-poster.png" :data-src="`/static_files/astore-mini.png`" alt="">
              </nuxt-link>
            </div>
            <!-- <div style="text-align: right;">
              <a href="mailto:@support@unotalone.su" target="_blank">support@unotalone.su</a>
            </div> -->
          </div>
        </footer>
      </div>
    </div>

    <!-- <template v-if="$route.name.includes('index') || $route.name.includes('catalog') || $route.name.includes('catalog-id')">
      <LastArticles v-if="lazyBlocks['la']" />
      <div v-else data-block-id="la" class="last-articles lazy-block" />
    </template> -->

    <LastArticles v-if="(!$route.name.includes('vmeste') && !$route.name.includes('chat')) && lazyBlocks['la']" />
    <div v-else data-block-id="la" class="last-articles lazy-block" />

    <Error v-if="error" :error="error" />

    <Hello v-if="showHello" @close="helloClose" />
    
    <!-- <FB /> -->

    <!-- <i @click="$store.commit('toggleFeedback', true)" class="icon-fb" /> -->
  </div>
</template>

<script>
// import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    Snow: () => import('~/components/Snow.vue'),
    MovieAds: () => import('~/components/MovieAds.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    Hello: () => import('~/components/Hello.vue'),
    LastArticles: () => import('~/components/LastArticles.vue'),
    Error: () => import('~/components/Error.vue'),
    SH: () => import('~/components/SH.vue'),
    ChristmasLights: () => import('~/components/ChristmasLights.vue'),
    // MarketPlace: () => import('~/components/MarketPlace.vue'),
    // FB: () => import('~/components/FB.vue'),
    // Menu: () => import('~/components/Menu.vue')
  },
  filters: {
    filterDate(value) {
      // const months = [
      //   'янв',
      //   'фев',
      //   'мар',
      //   'апр',
      //   'мая',
      //   'июн',
      //   'июл',
      //   'авг',
      //   'сен',
      //   'окт',
      //   'ноя',
      //   'дек'
      // ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1
      const year = new Date(value).getFullYear()
      // const hour =
      //   new Date(value).getHours() >= 10
      //     ? new Date(value).getHours()
      //     : '0' + new Date(value).getHours()
      // const min =
      //   new Date(value).getMinutes() >= 10
      //     ? new Date(value).getMinutes()
      //     : '0' + new Date(value).getMinutes()
      return `${day}.${month}.${year}` // ${hour}:${min}`
    }
  },
  watch: {
    ecoMode(val) {
      if (val) {
        if (this.showSnow) this.hideSnow()
      } else if (!this.showSnow) {
        this.hideSnow()
      }
      localStorage.setItem('eco', String(val))
    },
    viewsPauseVal(val) {
      if (val) {
        this.$store.commit('setError', { status: 'info', msg: 'Просмотры без рекламы приостановлены' })
      } else {
        this.$store.commit('setError', { status: 'info', msg: 'Просмотры без рекламы возобновлены' })
      }
      setTimeout(() => {
        this.$store.commit('clearError')
        location.reload()
      }, 1500)
    },
    // menuMinimize(val) {
    //   if (!val) {
    //     this.$nextTick(() => {
    //       this.lazyLoadImgs()
    //     })
    //   }
    // },
    roomsGroup(val) {
      if (val) {
        this.getRoomsGroup()
      } else {
        // this.roomsList = this.roomsListD
        this.getRooms()
      }
    },
    myRooms(val) {
      this.getRooms()
    },
    changeRoute() {
      setTimeout(() => {
        document.body.style.overflow = ''
        // this.lazyLoadImgs()
      }, 100)

      if (this.isDesktop && !this.$route.name.includes('vmeste-id')) {
        this.getRoomsInverval = setInterval(() => this.getRooms(), 5 * 60 * 1000)
      } else {
        clearInterval(this.getRoomsInverval)
      }

      // if (!this.showAdfinity && !this.isDesktop) {
      //   if (!this.user || this.user.group === 1) {
      //     const adfinity = document.createElement('script')
      //     adfinity.async = true
      //     adfinity.src = `https://cdn.adfinity.pro/code/unotalone.su/adfinity.js`
      //     document.head.appendChild(adfinity)

      //     this.showAdfinity = true
      //   }
      // }
      
      // const contentScroll = document.querySelector('.content-scroll')
      // if (contentScroll) contentScroll.scroll(0, 0)

      this.showUserMenu = false
      if (!this.isDesktop) this.roomsList = []

      this.inputyt = false
      this.ytSrc = ''
    
      // document.querySelector('.content-scroll').removeEventListener('scroll', this.lazyLoadImgs)

    //   if (
    //     // !this.isDesktop &&
    //     (!this.user || this.user.group === 1)
    //   ) {
    //     if (this.showFS) return
    //     const rsyaFullscreen = document.createElement('script')
    //     rsyaFullscreen.innerHTML = `
    // window.yaContextCb.push(()=>{
    //   Ya.Context.AdvManager.render({
    //     "blockId": ${this.isDesktop ? '"' + 'R-A-6711067-5' + '"' : '"' + 'R-A-6711067-4' + '"'},
    //     "type": "fullscreen",
    //     "platform": ${this.isDesktop ? '"' + 'desktop' + '"' : '"' + 'touch' + '"'}
    //   })
    // })`
    //     document.body.appendChild(rsyaFullscreen)
    //     this.showFS = true
    //   }

      // this.$nextTick(() => {
      //   const cScroll = document.querySelector('.content-scroll')
      //   if (cScroll) {
      //     cScroll.style.overflow = ''
      //     cScroll.addEventListener('scroll', this.lazyLoadImgs)
      //     cScroll.scroll(0, 0)
      //   }
      // })
      if (this.showCatalogMenu) this.showCatalogMenu = false
    },
    tabActive(val) {
      if (!val) {
        clearInterval(this.getRoomsInverval)
      } else if (this.isDesktop && !this.$route.name.includes('vmeste-id')) {
        this.getRooms()
        this.getRoomsInverval = setInterval(() => this.getRooms(), 5 * 60 * 1000)
      }
    },
    inputyt(val) {
      if (val) {
        this.ytSrc = ''
      }
    },
    async ytSrc(val) {
      if (val) {
        if (
          val.match(/https:\/\/youtu\.be\/.*/) ||
          val.match(/https:\/\/www\.youtube\.com\/watch\?v=.*/)
        ) {
          const body = {
            src: val,
          }
          const { data } = await this.$axios.post(`/api/cinema/youtube`, body)
          if (!data.error) {
            this.$router.push(`/vmeste/${data.id}`)
          }
        } else {
          this.$store.commit('setError', { status: 'info', msg: 'Неверный формат youtu.be/Ju86mknumYM' })
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      }
    },
  },
  computed: {
    ...mapState(['error', 'isDesktop', 'user', 'tabActive', 'playerPlay', 'theme', 'popular']),
    inRoom() {
      return this.$route.name.includes('vmeste-id')
    },
    changeRoute() {
      return this.$route.fullPath
    },
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    lang () {
      return this.$i18n.locale
    },
  },
  data() {
    const christmasTime = (new Date().getMonth() === 11 && new Date().getDate() > 15) || (new Date().getMonth() === 0 && new Date().getDate() < 15)

    return {
      christmasTime,
      showAdfinity: false,
      showCatalogMenu: false,
      ecoMode: false,
      showHello: false,
      roomsGroup: false,
      showSnow: false,
      showFS: false,
      themeColor: 'fb2d7f',
      lastupdate: Date.now(),
      roomsListD: [],
      roomsList: [],
      roomsCount: 0,
      myRooms: null,
      roomsPage: 0,
      showUserMenu: false,
      showEditName: false,
      newName: '',
      // showMyRooms: false,
      onBlurTimeout: null,
      getRoomsInverval: null,
      showVideoAd: true,
      ytSrc: '',
      inputyt: false,
      menuMinimize: false,
      loading: false,
      viewsPauseVal: null,
      lazyBlocks: {
        la: false
      },
    }
  },
  mounted() {
    if (localStorage.getItem('snow')) {
      this.showSnow = false
    } else {
      this.showSnow = true
    }

    // if (['unotalone.ru'].includes(window.location.hostname)) {
    //   setTimeout(() => {
    //     location.href = 'https://unotalone.su'
    //   }, 1000)
    // }

    // if (this.$route.name !== 'index') {
    //   const refreshDate = localStorage.getItem('refresh_date')
    //   const d = new Date()
    //   const dateNow = `${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`

    //   if (!refreshDate || refreshDate !== dateNow) {
    //     localStorage.setItem('refresh_date', dateNow)
    //     location.reload()
    //   }
    // }


    if (this.$route.query.ref) {
      localStorage.setItem('referer', this.$route.query.ref)
    }

    if (this.user && this.$route.query.promo) {
      this.postPromo()
    }
    
    if (this.user && localStorage.getItem('referer') && localStorage.getItem('referer').length) {
      this.$axios.post(`/api/user/ref`, { referer: localStorage.getItem('referer') })
      localStorage.setItem('referer', '')
    }

    const userLang = navigator.language || navigator.userLanguage

    // console.log('userLang', userLang)

    if (
      userLang &&
      ['ru', 'en', 'be', 'uk'].includes(userLang) &&
      this.$route.name.includes('__ru')
    ) {
      this.$store.commit('setBrowserLang', userLang)

      this.$router.push(this.switchLocalePath(userLang))
    }

    const vm = this

    if (!this.isDesktop) {
      const mobileElem = document.querySelector('.mobile')
      const menuElem = document.querySelector('.mobile .menu')
      // const menuCatalogElem = document.querySelector('.mobile .menu .menu-catalog')

      setInterval(() => {
        let findBlock = 0
        document.body.querySelectorAll('div').forEach(el => {
          if (el.style.zIndex === '2147483647') {
            findBlock = 1

            if (menuElem && mobileElem) {
              if (el.children[1] && el.children[1].style.opacity === '1') {
                menuElem.style.bottom = 31 + 'vh'
                mobileElem.style.paddingBottom = 31 + 'vh'
                
              } else {
                menuElem.style.bottom = 0 + 'px'
                mobileElem.style.paddingBottom = 0 + 'px'
              }
            }

            // if (menuCatalogElem) {
            //   if (el.children[1] && el.children[1].style.opacity === '1') {
            //     menuElem.style.bottom = 31 + 'vh'
            //   } else {
            //     menuElem.style.bottom = 0 + 'px'
            //   }
            // }
          }
        })

        if (!findBlock) {
          if (menuElem && mobileElem) {
            menuElem.style.bottom = 0 + 'px'
            mobileElem.style.paddingBottom = 0 + 'px'
          }
        }
      }, 1000)
    }
    
    if (this.user && localStorage.getItem('eco')) {
      const val = JSON.parse(localStorage.getItem('eco'))
      this.ecoMode = val
    }   

    const dateDay = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
    if (this.user && !this.$route.name.includes('profile-activity') && !localStorage.getItem(`hello`) && !localStorage.getItem(`hello_${dateDay}`)) {
      setTimeout(() => {
        this.showHello = true
      }, 180 * 1000)
    }

    // let vh = window.innerHeight * 0.01
    // document.documentElement.style.setProperty('--vh', `${vh}px`)

    // window.addEventListener('resize', () => {
    //   vh = window.innerHeight * 0.01
    //   document.documentElement.style.setProperty('--vh', `${vh}px`)
    // })

    // window.addEventListener('scroll', this.lazyLoadImgs)
    // this.lazyLoadImgs()
    
    window.addEventListener('scroll', this.showBlock)
    
    if (this.isDesktop) {
      window.addEventListener('scroll', () => {
        const elem = document.querySelector('.menu')
        if (window.scrollY > 0) {
          elem.classList.add('sticky')
        } else {
          elem.classList.remove('sticky')
        }
      })
    }

    // const userInteractionEvents = ["mouseover", "keydown", "touchstart", "touchmove", "wheel"];
    // userInteractionEvents.forEach((event) => {
    //   window.addEventListener(event, () => {
    //     this.showAds()

    //     if (vm.isDesktop) {
    //       vm.getRooms()
    //       vm.getRoomsInverval = setInterval(() => vm.getRooms(), 5 * 60 * 1000)
    //     }

    //     setInterval(() => {
    //       vm.showVideoAd = true
    //     }, 5 * 60 * 1000)
    //   }, { passive: true }) }
    // )

    if (this.isDesktop) {
      this.getRooms()

      if (this.user) {
        this.getRoomsInverval = setInterval(() => vm.getRooms(), 5 * 60 * 1000)
      }
    }

    // window.addEventListener('blur', this.onBlur)
    // window.addEventListener('focus', this.onFocus)

    let hidden = "hidden";

    // Standards:
    if (hidden in document) {
      document.addEventListener("visibilitychange", onchange);
    } else if ((hidden = "mozHidden") in document) {
      document.addEventListener("mozvisibilitychange", onchange);
    } else if ((hidden = "webkitHidden") in document) {
      document.addEventListener("webkitvisibilitychange", onchange);
    } else if ((hidden = "msHidden") in document) {
      document.addEventListener("msvisibilitychange", onchange);
    } else if ("onfocusin" in document) {
      // IE 9 and lower:
      document.onfocusin = document.onfocusout = onchange;
    } else {
      // All others:
      window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
    }

    function onchange (evt) {
      const v = "visible"
      const h = "hidden"
      const evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h
      };

      evt = evt || window.event;

      if (evt.type in evtMap) {
        document.body.className = evtMap[evt.type];
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', evtMap[evt.type] === 'hidden' ? 0 : 1)
        }
      } else {
        document.body.className = this[hidden] ? "hidden" : "visible";
        if (vm.isDesktop) {
          // if (!vm.playerPlay)
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        } else {
          vm.$store.commit('setTabActive', this[hidden] ? 0 : 1)
        }
      }
      // console.log(vm.tabActive)
    }

    // set the initial state (but only if browser supports the Page Visibility API)
    if (document[hidden] !== undefined) {
      onchange({ type: document[hidden] ? "blur" : "focus" });
    }

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');

      if (this.isDesktop) {
        const mainLayout = document.querySelector('.main-layout')
        const width = document.body.clientWidth

        if (width > 1200 && width < 1600) {
          this.menuMinimize = true
          mainLayout.classList.remove('small')
        } else if (width < 1200) {
          this.menuMinimize = true
          mainLayout.classList.add('small')
        } else {
          this.menuMinimize = false
          mainLayout.classList.remove('small')
        }
      }
    })

    this.showAds()
  },
  methods: {
    async postPromo() {
      const { data } = await this.$axios.post(`/api/user/promo`, { promo: this.$route.query.promo })

      if (!data.error) {
        location.href = '/chat'
      }
      // else {
      //   this.$store.commit('setError', { status: 'info', msg: data.error.message })
      //   setTimeout(() => this.$store.commit('clearError'), 2000)
      // }
    },
    createRoom() {
      if (this.$route.name.includes('catalog-id')) {
        const id = this.$route.params.id
          ? this.$route.params.id.lastIndexOf('-') > 0
            ? this.$route.params.id.slice(0, this.$route.params.id.lastIndexOf('-'))
            : this.$route.params.id
          : null
          
        if (!id) return

        this.$router.push(this.localePath(`/vmeste?id=${id}`))
      } else {
        this.$router.push(this.localePath('/'))

        setTimeout(() => {
          const elem = document.querySelector('.manual .desc')
          if (elem) {
            elem.scrollIntoView()
            
            const input = document.querySelector('.manual input')
            if (input) input.focus()
          }
        }, 1000)
      }
    },
    showAds() {
      if (!this.user || this.user.group === 1) {
        const vm = this
        
        setInterval(() => {
          vm.showVideoAd = true
        }, 5 * 60 * 1000)

        // const adfinity = document.createElement('script')
        // adfinity.async = true
        // adfinity.src = `https://cdn.adfinity.pro/code/unotalone.su/adfinity.js`
        // document.head.appendChild(adfinity)

        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
  window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-6711067-1',
    blockId: 'R-A-6711067-1'
    })
  })`
          document.body.appendChild(rsyaHorizon)

          const rsyaInf = document.createElement('script')
          rsyaInf.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-6711067-6',
  blockId: 'R-A-6711067-6'
  })
})`
          document.body.appendChild(rsyaInf)

          if (!this.isDesktop) {
            const rsyaFloorAd = document.createElement('script')
            rsyaFloorAd.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  "blockId": "R-A-6711067-3",
  "type": "floorAd",
  "platform": "touch"
  })
})`
//             rsyaFloorAd.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//   "blockId": ${this.isDesktop ? '"' + 'R-A-6711067-7' + '"' : '"' + 'R-A-6711067-3' + '"'},
//   "type": "floorAd",
//   "platform": ${this.isDesktop ? '"' + 'desktop' + '"' : '"' + 'touch' + '"'}
//   })
// })`
            document.body.appendChild(rsyaFloorAd)

            const rsyaFullscreen = document.createElement('script')
            rsyaFullscreen.innerHTML = `
window.yaContextCb.push(() => {
  Ya.Context.AdvManager.render({
      "blockId": "R-A-6711067-4",
      "type": "fullscreen",
      "platform": "touch"
  })
})`
//           rsyaFullscreen.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//     "blockId": ${this.isDesktop ? '"' + 'R-A-6711067-5' + '"' : '"' + 'R-A-6711067-4' + '"'},
//     "type": "fullscreen",
//     "platform": ${this.isDesktop ? '"' + 'desktop' + '"' : '"' + 'touch' + '"'}
//   })
// })`
            document.body.appendChild(rsyaFullscreen)
          } else {
          const rsyaDesktopVideoAd = document.createElement('script')
          rsyaDesktopVideoAd.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.render({
renderTo: 'yandex_rtb_R-A-2003630-3',
blockId: 'R-A-2003630-3'
})
})`
          document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
    helloClose() {
      this.showHello = false
      const dateDay = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()
      localStorage.setItem(`hello_${dateDay}`, 'true')
    },
    showBlock() {
      // if (!this.scrolled) this.scrolled = true
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.lazy-block')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockName = block.dataset.blockId
        if (visible > 0 && !this.lazyBlocks[blockName]) {
          this.lazyBlocks[blockName] = true
        }
      })
    },
    async viewsPause() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/user/views-pause`)
      this.loading = false
      const user = this.user
      user.views_pause = data
      this.viewsPauseVal = data
      this.$store.commit('setUser', user)
    },
    filterRooms(type) {
      let rooms = []
      if (type === 'common') {
        rooms = this.roomsListD.filter(el => el.common)
      } else if (type === 'opened') {
        rooms = this.roomsListD.filter(el => !el.hidden)
      } else if (type === 'all') {
        rooms = this.roomsListD
      }
      this.roomsList = rooms
    },
    hideSnow() {
      if (localStorage.getItem('snow')) {
        localStorage.removeItem('snow')
      } else {
        localStorage.setItem('snow', 'true')
      }
      this.showSnow = !this.showSnow
      // this.showUserMenu = false
    },
    shareID() {
      const input = document.querySelector(`[data-user-id="${this.user.id}"]`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'ID скопирован' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    switchColor(lang) {
      document.body.dataset.lang = lang
    }, 
    async switchTheme(theme) {
      if (theme === this.theme) return
      const { data } = await this.$axios.post(`/api/user/theme`, { theme })
      // document.body.dataset.theme = data.theme

      if (data.error) {
        this.$store.commit('setError', { status: 'info', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.$store.commit('setTheme', data.theme)
      }
    }, 
    onBlur() {
      console.log('onBlur')
      
      this.onBlurTimeout = setTimeout(() => {
        if (!this.playerPlay) this.$store.commit('setTabActive', 0)
      }, 20 * 1000)
    },
    onFocus() {
      console.log('onFocus')

      clearTimeout(this.onBlurTimeout)

      this.$store.commit('setTabActive', 1)
    },
    // myRooms() {
    //   if (!this.user) return
    //   this.showMyRooms = !this.showMyRooms
    //   if (this.showMyRooms) {
    //     const rooms = []
    //     this.roomsD.forEach(el => {
    //       if (el.owner && el.owner.id === this.user.id) {
    //         rooms.push(el)
    //       }
    //     })
    //     this.rooms.list = rooms
    //   } else {
    //     this.rooms.list = this.roomsD
    //   }
    // },
    async changeName() {
      if (!this.user) return
      const badName = ['Alon', 'bot', 'admin', 'administrator', 'unotalone', 'moderator', 'админ', 'бот', 'анимист', 'администратор']
      if (
        !this.newName.trim() ||
        badName.includes(this.newName.toLowerCase()) ||
        this.newName.match(/[^a-zA-Zа-яА-Я0-9 _].+/)
      ) {
        this.$store.commit('setError', { status: 'warn', msg: 'Неверный формат никнейма' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length < 3) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком короткое имя (мин. 3)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length > 30) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком длинное имя (макс. 30)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const { data } = await this.$axios.post('/api/user/nickname', { name: this.newName.trim() })
        if (data.error) {
          this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        } else {
          const user = this.user
          user.name = data.name
          this.$store.commit('setUser', user)
          this.newName = ''
          this.showEditName = false
          this.$store.commit('setError', { status: 'ok', msg: 'Никнейм успешно изменен' })
        }
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    avatarUploadTrigger() {
      this.$refs.avatarUpload.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 1000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight, finalFile

              if (typeImg === 'avatar') {
                maxWidth = 120
                maxHeight = 120
              }

              if (typeImg === 'avatar' && imageExt === '.gif') {
                finalFile = reader.result
              } else {
                let imageWidth = image.width
                let imageHeight = image.height

                if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                  imageHeight *= maxWidth / imageWidth
                  imageWidth = maxWidth
                } else if (imageHeight > maxHeight) {
                  imageWidth *= maxHeight / imageHeight
                  imageHeight = maxHeight
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight

                const ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                finalFile = canvas.toDataURL(fileType)
              }

              // The resized file ready for upload
              const fileName = `${vm.user.id}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              vm.$store.commit('setError', { status: 'load', msg: 'Идет загрузка' })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=${typeImg}s`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              vm.$store.commit('setError', { status: 'ok', msg: 'Аватар установлен' })
              setTimeout(() => vm.$store.commit('clearError'), 1000)
              if (typeImg === 'avatar') {
                const photoUrl = data.url + '?' + Date.now()
                await vm.$axios.put('/api/user', { photo: photoUrl })
                const user = vm.user
                user.photo = photoUrl
                vm.$store.commit('setUser', user)
              }
            }
          }
        } else {
          this.$store.commit(
            'setError',
            'Файл не соответствует условиям: формат .gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    showLeftSide() {
      if (this.roomsList.length) {
        this.roomsList = []
        document.body.style.overflow = ''
      } else {
        this.getRooms()
        document.body.style.overflow = 'hidden'
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      // location.reload()
      location.href = '/signin'
    },
    roomsMore() {
      // this.lazyLoadImgs()

      const roomsMoreElem = document.querySelector('.rooms-list .items .rooms-more')
    
      if (roomsMoreElem) {
        const docHeight = document.documentElement.clientHeight
        const topPosition = docHeight - roomsMoreElem.getBoundingClientRect().top
        if (+topPosition) {
          ++this.roomsI
        }
      }
    },
    getRoomsGroup() {
      this.$socket.emit(`unotalone:rooms:group`, (cb) => {
        // this.roomsGroup = false
        this.roomsCount = cb.count
        this.roomsList = cb.list
        this.roomsListD = cb.list

        // this.$nextTick(() => {
        //   this.lazyLoadImgs()
        // })
      })

      // const rooms = {}


      // this.roomsListD.forEach(el => {
      //   if (!rooms[el.kinopoisk_id]) {
      //     rooms[el.kinopoisk_id] = el
      //   } else {
      //     rooms[el.kinopoisk_id].viewers += el.viewers
      //   }
      // })
      // this.roomsList = Object.keys(rooms).map(key => rooms[key])
    },
    getRooms(page = 0) {
      const body = {
        page,
        my: this.myRooms ? this.user.id : null,
        user_id: this.user ? this.user.id : null,
      }
      this.$socket.emit(`unotalone:rooms:get`, body, (cb) => {
        this.roomsGroup = false
        this.roomsCount = cb.count
        const rooms = this.roomsPage ? this.roomsList.concat(cb.list) : cb.list
        this.roomsList = rooms
        this.roomsListD = rooms

        // this.$nextTick(() => {
        //   this.lazyLoadImgs()
        //   const roomsListElem = document.querySelector('.rooms-list .items')
        //   if (roomsListElem) {
        //     roomsListElem.removeEventListener('scroll', this.lazyLoadImgs)
        //     roomsListElem.addEventListener('scroll', this.lazyLoadImgs)
        //     // roomsListElem.removeEventListener('scroll', this.roomsMore)
        //     // roomsListElem.addEventListener('scroll', this.roomsMore)
        //   }
        // })
      })
    },
  },
}
</script>

<style>
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus, textarea:focus, input:focus {
      font-size: 16px;
    }
  }
</style>
